import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'library/common/commonComponents/Loader';
import Input from 'library/common/commonComponents/Inputs/Input';
import styles from './employeeHistoryContainer.module.scss';
import { getEmployeeInformation, getAllEmployeeInformation } from 'library/api/employee';
import { useSelector } from 'react-redux';
import EmployeeReviewCheckInOutContainer from './EmployeeReviewCheckInOutContainer';
import EmployeeCheckInOutHistory from './EmployeeCheckInOutHistory';

const EmployeeHistoryContainer = () => {
  const [searchValue, setSearchValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [employees, setEmployees] = useState([]);
  const { t } = useTranslation();
  const user = useSelector(state => state.userReducer);

  useEffect(() => {
    setIsLoading(true);

    // load personal employeeInfo or all depending on admin access
    if (user.administrationAccess) {
      getAllEmployeeInformation()
        .then(res => {
          setEmployees(res.data);
        })
        .catch(err => console.log(err))
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      getEmployeeInformation(user.id)
        .then(res => {
          // set employee similar to getAllEmployeeInformation response for easier handling in child components
          setEmployees([
            {
              user: res.data.user,
              employeeInformation: res.data,
            },
          ]);
        })
        .catch(err => console.log(err))
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, []);

  const filteredEmployees =
    searchValue.length > 0
      ? employees.filter(item =>
          `${item.user.firstName} ${item.user.lastName}`.toLowerCase().includes(searchValue),
        )
      : employees;

  return (
    <div>
      <div className={styles.searchInputWrap}>
        <Input
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
          placeholder={t('Checkinout.Search by name')}
        />
      </div>

      {isLoading ? (
        <Loader />
      ) : (
        <div className={styles.containerEmployeeHistory}>
          {filteredEmployees.map(employee => (
            <EmployeeCheckInOutHistory key={employee.user.id} employee={employee} />
          ))}
        </div>
      )}

      <EmployeeReviewCheckInOutContainer />
    </div>
  );
};

export default EmployeeHistoryContainer;
