import React, { useState, useMemo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import cn from 'classnames';
import { sanitize } from 'dompurify';

import CheckBox from 'library/common/commonComponents/Checkbox';
import Button from 'library/common/commonComponents/Buttons/Button';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import Tooltip from 'library/common/commonComponents/Tooltip';
import MoreBtn from 'library/common/commonComponents/Buttons/MoreBtn';
import Loader from 'library/common/commonComponents/Loader';
import Label from 'library/common/commonComponents/Label';
import Popup from 'library/common/commonComponents/Popups/Popup';
import ProfileAvatar from 'library/common/commonComponents/ProfileAvatar';
import WithWatsonTranslate from 'library/common/commonComponents/WithWatsonTranslate/version';
import { updatePermanentStatus } from 'library/api/posts';
import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import { assignTaskToUser, unassignTaskFromUser } from 'library/api/tasks';

import styles from './taskHeader.module.scss';

export default function TaskHeader({
  isOpened,
  onOpen,
  onUpdateTaskStatus,
  onEdit,
  onDelete,
  isEditing,
  taskStatus,
  title,
  isScheduling,
  end,
  assignedUsersList,
  responsibleUsersList,
  reviewRequired,
  comments,
  user,
  creatorId,
  proceedUnassignedTask,
  isAdmin,
  id,
  permanent,
  manageTasks,
  isTranslationAllowed,
  editDeleteTask,
  maxAssignedUserCount,
}) {
  const { t } = useTranslation();
  const [deleteTaskIsOpened, setDeleteTaskIsOpened] = useState(false);
  // eslint-disable-next-line
  const isResponsible = useMemo(() => responsibleUsersList.find(item => item.id === user.id), [
    responsibleUsersList,
  ]);
  const isAssigned = assignedUsersList.find(item => item.id === user.id);

  const canEditTask =
    user.id === creatorId ||
    (!assignedUsersList.length && proceedUnassignedTask) ||
    isAdmin ||
    isResponsible;



  function maxAssingedCountReached() {
    if (Number.isInteger(maxAssignedUserCount)) {
      if (assignedUsersList.length >= maxAssignedUserCount) {
        return true;
      }
    }
    return false;
  }

  const changePermanentOfTask = currentStatus => {
    let success = true;
    updatePermanentStatus(id)
      .then(() => {
        setDropdownOptions(dropdownSetter(!currentStatus));
      })
      .catch(() => {
        success = false;
      })
      .finally(() => {
        store.dispatch(
          showBottomNotification(
            <Trans i18nKey={`BottomNotifications.${success ? 'Saved' : 'Something went wrong'}`} />,
            { isFail: !success },
          ),
        );
      });
  };

  const assignToMeObject = {
    faIcon: 'fa-street-view',
    title: t('Tasks.Assign to me'),
    onClick: () => {
      assignTaskToUser(id);
      assignedUsersList.push(user);
      setDropdownOptions(prev => {

        return [unassignFromMeObject, ...prev.filter(obj => obj.faIcon !== 'fa-street-view')];
      });
    },
  };

  const unassignFromMeObject = {
    faIcon: 'fa-minus-square-o',
    title: t('Tasks.Unassign'),
    onClick: () => {
      unassignTaskFromUser(id);
      for (var i = assignedUsersList.length - 1; i >= 0; --i) {
        if (assignedUsersList[i].id === user.id) {
          assignedUsersList.splice(i, 1);
        }
      }

      setDropdownOptions(prev => {
        return [assignToMeObject, ...prev.filter(obj => obj.faIcon !== 'fa-minus-square-o')];
      });
    },
  }

  const dropdownSetter = perm => {
    return canEditTask
      ? [
        (isAssigned)
          ? unassignFromMeObject
          : null,
        (!isAssigned && !maxAssingedCountReached())
          ? assignToMeObject
          : null,
        {
          faIcon: 'fa-pencil',
          title: isEditing ? t('Tasks.Cancel edit task') : t('Tasks.Edit task dropdown'),
          onClick: () => {
            onEdit();
          },
        },
        {
          faIcon: 'fa-trash-o',
          title: t('Tasks.Delete task'),
          onClick: () => {
            setDeleteTaskIsOpened(true);
          },
        },
        manageTasks
          ? {
            faIcon: perm ? 'fa-unlock' : 'fa-lock',
            title: perm ? t('Post.Revoke permanent') : t('Post.Make permanent'),
            onClick: () => changePermanentOfTask(perm),
          }
          : null,
      ].filter(option => option !== null)
      : [
        !isAssigned && editDeleteTask && !maxAssingedCountReached()
          ? assignToMeObject
          : null,
        isAssigned && editDeleteTask
          ? unassignFromMeObject
          : null,
      ].filter(option => option !== null);
  };

  const [dropdownOptions, setDropdownOptions] = useState(dropdownSetter(permanent));

  const date = new Date();
  const deadline = new Date(end);
  const handleStatusChange = async e => {
    if (isSubmiting) {
      return;
    }
    setIsSubmiting(true);
    await onUpdateTaskStatus(
      reviewRequired ? (isResponsible ? 'completed' : 'pending_review') : 'completed',
    );
    e.stopPropagation();
    setIsSubmiting(false);
  };
  const daysRemaining = Math.ceil((end - date.getTime()) / (1000 * 60 * 60 * 24));
  const [isSubmiting, setIsSubmiting] = useState(false);
  const handleDelete = async () => {
    if (isSubmiting) {
      return;
    }
    setIsSubmiting(true);
    await onDelete();
    setIsSubmiting(false);
  };

  return (
    <div className={cn(styles.container, isOpened && styles.containerOpened)} onClick={onOpen}>
      <div className={styles.headerLeft}>
        <i className='fa fa-bars' />
        <div className={styles.checkboxStatusContainer}>
          <CheckBox
            className={cn(
              styles.checkboxStatus,
              taskStatus === 'completed' && styles.checkboxStatusCompleted,
            )}
            name={<span dangerouslySetInnerHTML={{ __html: sanitize(title) }} />}

            isChecked={taskStatus === 'completed'}
            isDisabled={
              isSubmiting ||
              taskStatus === 'completed' ||
              (!isAssigned &&
                !isResponsible &&
                (taskStatus === 'pending_review' ||
                  (!assignedUsersList.length && !proceedUnassignedTask))) ||
              (!isResponsible && taskStatus === 'pending_review')
            }
            onChange={e => handleStatusChange(e)}
          />
        </div>
        <div className={styles.labelContainer}>
          {isSubmiting ? (
            <Loader dotColor='#777' dotSize='10px' className={styles.submitting} />
          ) : taskStatus === 'pending' ? (
            <Label type='default' className={styles.pendingLabel}>
              <i className='fa fa fa-info-circle' /> {t('Tasks.PENDING')}
            </Label>
          ) : taskStatus === 'in_progress' ? (
            <Label type='default' className={styles.inProgressLabel}>
              <i className='fa fa fa-edit' /> {t('Tasks.IN PROGRESS')}
            </Label>
          ) : taskStatus === 'pending_review' ? (
            <Label type='default' className={styles.pendingReviewLabel}>
              <i className='fa fa fa-exclamation-triangle' /> {t('Tasks.PENDING REVIEW')}
            </Label>
          ) : taskStatus === 'completed' ? (
            <Label type='default' className={styles.completedLabel}>
              <i className='fa fa-check-square' /> {t('Tasks.COMPLETED')}
            </Label>
          ) : null}
          {end && date.getTime() > end && taskStatus !== 'completed' && (
            <Label type='default' className={styles.overdueLabel}>
              <i className='fa fa fa-exclamation-triangle' /> {t('Tasks.OVERDUE')}
            </Label>
          )}
        </div>
        {!isAssigned && (
            <div
              className={styles.taskAssignButton}
              onClick={() => {
                assignTaskToUser(id);
                assignedUsersList.push(user);
              }}
            >
              <i className='fa fa-street-view' aria-hidden='true' />
              {t('Tasks.Assign to me')}
            </div>
        )}
        {(maxAssignedUserCount !== null && maxAssignedUserCount !== '') &&
          <div style={{ marginLeft: "15px" }}>{`(${assignedUsersList.length}/${maxAssignedUserCount})`}</div>
        }
      </div>
      <div className={styles.headerRight}>
        <div className={styles.userListsContainer}>
          <div className={styles.responsibleUsersListContainer}>
            {responsibleUsersList.length !== 0 &&
              responsibleUsersList.map(item => (
                <Tooltip
                  key={item.id}
                  text={
                    <span className='notranslate'>
                      {item.firstName} {item.lastName} {t('Tasks.is responsible')}
                    </span>
                  }
                  isVisibleCondition
                >
                  <div className={styles.avatar}>
                    <ProfileAvatar image={item} id={item.id} withoutLink />
                  </div>
                </Tooltip>
              ))}
            {responsibleUsersList.length !== 0 && assignedUsersList.length === 0 && (
              <div className={styles.headerRightSeparator} />
            )}
          </div>
          <div className={styles.assignedUsersListContainer}>
            {assignedUsersList.length !== 0 &&
              assignedUsersList.map(item => (
                <Tooltip
                  key={item.id}
                  text={
                    <span className='notranslate'>
                      {item.firstName} {item.lastName} {t('Tasks.is assigned')}
                    </span>
                  }
                  isVisibleCondition
                >
                  <div className={styles.avatar}>
                    <ProfileAvatar image={item} id={item.id} withoutLink />
                  </div>
                </Tooltip>
              ))}
            {assignedUsersList.length !== 0 && <div className={styles.headerRightSeparator} />}
          </div>
        </div>
        {reviewRequired && (
          <div className={styles.reviewRequiredContainer}>
            <Tooltip text={t('Tasks.This task requires to be reviewed')} isVisibleCondition>
              <i className='fa fa-eye' />
            </Tooltip>
            <div className={styles.headerRightSeparator} />
          </div>
        )}
        {isScheduling && (
          <div
            className={cn(
              styles.isSchedulingContainer,
              date.getDay() === deadline.getDay() && styles.isSchedulingContainerToday,
              date.getTime() > end && styles.isSchedulingContainerOverdue,
            )}
          >
            <Tooltip
              text={
                date.getTime() > end
                  ? t('Tasks.OVERDUE').toLowerCase()
                  : date.getDay() === deadline.getDay()
                    ? t('Tasks.Today')
                    : `${daysRemaining} ${t('Tasks.days remaining')}`
              }
              isVisibleCondition
            >
              <i className='fa fa-clock-o' />
            </Tooltip>
            <div className={styles.headerRightSeparator} />
          </div>
        )}
        <div className={styles.commentsCountContainer}>
          <i className='fa fa-comment-o' />
          {comments.length}
          {canEditTask && <div className={styles.headerRightSeparator} />}
        </div>
        <div className={styles.moreButtonsContainer} onClick={e => e.stopPropagation()}>
          {isSubmiting ? (
            <Loader dotColor='#777' dotSize='10px' className={styles.submitting} />
          ) : (
            <MoreBtn
              faIcon='fa fa-ellipsis-v'
              dropdownOptions={dropdownOptions}
              className={styles.moreButtons}
            />
          )}
        </div>
        <div className={styles.deleteTaskContainer} onClick={e => e.stopPropagation()}>
          <Popup
            size='extraSmall'
            isOpened={deleteTaskIsOpened}
            header={<Trans i18nKey='Tasks.Confirm Action' />}
            closePopup={() => setDeleteTaskIsOpened(false)}
            footer={
              <div className={styles.buttonsContainer}>
                <div className={styles.buttonCancel}>
                  <Button onClick={() => setDeleteTaskIsOpened(false)}>{t('Tasks.Cancel')}</Button>
                </div>
                <div className={styles.buttonConfirm}>
                  <ButtonWithLoader onClick={handleDelete} type='primary' isLoading={isSubmiting}>
                    {t('Tasks.Confirm')}
                  </ButtonWithLoader>
                </div>
              </div>
            }
          >
            <div className={styles.wrapper}>
              {t('Tasks.Do you really want to perform this action')}
            </div>
          </Popup>
        </div>
      </div>
    </div >
  );
}
