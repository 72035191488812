import React, { useState, useRef, useEffect } from "react";
import styles from './thirdArticle.module.scss'
import { KitaPanelWrapper } from "library/common/commonComponents/KitaPanelWrapper";
import { Wrapper } from "library/common/commonComponents/Wrapper";
import Button from "library/common/commonComponents/Buttons/Button";
import TextArea from "library/common/commonComponents/Inputs/TextArea";
import shortid from "shortid";
import Popup from "library/common/commonComponents/Popups/Popup";
import { useTranslation } from "react-i18next";
import { saveArticle } from "library/api/kita";
import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import useReactRouter from 'use-react-router';
import { Carousel } from "react-responsive-carousel";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { isMatchesToMediaTypeArray } from "library/utilities/files";
import { deleteArticle, deleteArticleImage, getArticle, getThirdArticle, saveThirdArticle, updateArticle, updateThirdArticle, uploadImagesToArticle } from "library/api/articles";
import { connect } from 'react-redux';

import Loader from 'library/common/commonComponents/Loader';

const ThirdArticle = ({ activeKita }) => {
    const { t } = useTranslation();
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [files, setFiles] = useState([]);
    const fileInputRef = useRef(null);
    const [deletePopup, setDeletePopup] = useState(false)
    const [deleteID, setDeleteID] = useState(null);
    const [articleDescription, setArticleDescription] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const [isShowMode, setIsShowMode] = useState(true)
    const [selectedImages, setSelectedImages] = useState(null);
    const [articleData, setArticleData] = useState(null);
    const [updateFlag, setUpdateFlag] = useState(false);
    const [noArticle, setNoArticle] = useState(false);
    const [articleId, setArticleId] = useState(null);
    const [deleteIndex, setDeleteIndex] = useState(null);
    const [deleteFile, setDeleteFile] = useState(null);

    const [selectedFiles1, setSelectedFiles1] = useState([]);
    const [selectedFiles2, setSelectedFiles2] = useState([]);
    const [selectedFiles3, setSelectedFiles3] = useState([]);

    const [selectedImages1, setSelectedImages1] = useState([]);
    const [selectedImages2, setSelectedImages2] = useState([]);
    const [selectedImages3, setSelectedImages3] = useState([]);

    // Input states for each upload box
    const [inputFields1, setInputFields1] = useState({ name: '', function: '', contact: '', id: '' });
    const [inputFields2, setInputFields2] = useState({ name: '', function: '', contact: '', id: '' });
    const [inputFields3, setInputFields3] = useState({ name: '', function: '', contact: '', id: '' });

    const fileInputRef1 = useRef(null);
    const fileInputRef2 = useRef(null);
    const fileInputRef3 = useRef(null);
    const [isLoading, setIsLoading] = useState(false);



    useEffect(() => {
        firstReload();
    }, [])

    const firstReload = () => {
        setIsLoading(true);

        getThirdArticle(activeKita.kitaId, 3).then((res) => {
            setArticleData(res.data);
            let images = []

            setIsLoading(false);
            if (res.data.length > 0) {
                setUpdateFlag(true);
                setArticleId(res.data.id);
                setInputFields1({
                    name: res.data[0]?.firstName || '',
                    function: res.data[0]?.functionText || '',
                    contact: res.data[0]?.contactDate || '',
                    id: res.data[0]?.id || ''
                });
                setSelectedFiles1(res.data[0]?.images.map(img => ({
                    articleId: res.data[0].id,
                    id: img.id,
                    fileId: img.fileId,
                    filePath: img.filePath,
                    fileDescription: img.imageCaption
                })) || []);
                setSelectedImages1(res.data[0]?.images || []);

                // Prepopulate form 2 (if exists)
                setInputFields2({
                    name: res.data[1]?.firstName || '',
                    function: res.data[1]?.functionText || '',
                    contact: res.data[1]?.contactDate || '',
                    id: res.data[1]?.id || ''
                });
                setSelectedFiles2(res.data[1]?.images.map(img => ({
                    articleId: res.data[1].id,
                    id: img.id,
                    fileId: img.fileId,
                    filePath: img.filePath,
                    fileDescription: img.imageCaption
                })) || []);
                setSelectedImages2(res.data[1]?.images || []);

                setInputFields3({
                    name: res.data[2]?.firstName || '',
                    function: res.data[2]?.functionText || '',
                    contact: res.data[2]?.contactDate || '',
                    id: res.data[2]?.id || ''
                });
                setSelectedFiles3(res.data[2]?.images.map(img => ({
                    articleId: res.data[2].id,
                    id: img.id,
                    fileId: img.fileId,
                    filePath: img.filePath,
                    fileDescription: img.imageCaption
                })) || []);
                setSelectedImages3(res.data[2]?.images || []);
            } else {
                setNoArticle(true)

                setIsLoading(false);
            }
        }).catch((item) => {
            setNoArticle(true)

            setIsLoading(false)
        })
    }

    const filesizes = (bytes, decimals = 2) => {
        // ... Same logic as before ...
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / k ** i).toFixed(dm)) + ' ' + sizes[i];
    };

    const handleChange = (event, setSelectedFiles, setSelectedImages) => {
        const images = [...event.target.files];

        if (selectedImages) {
            setSelectedImages((previousImages) => [
                ...previousImages,
                images
            ]);
        } else {
            setSelectedImages(images)
        }

        setSelectedFiles((prevValue) => [
            ...prevValue,
            ...images.map((file) => ({
                id: shortid.generate(),
                fileId: file.name,
                filetype: file.type,
                filePath: null, // Initially set to null
                fileDescription: '',
                filesize: filesizes(file.size),
            })),
        ]);

        // Create a closure over the file for each reader to ensure you update the correct one.
        images.forEach((file) => {
            const reader = new FileReader();
            reader.onloadend = (e) => {
                const base64Image = e.target.result; // Get the base64 image data

                // Update the state with the base64 image for the corresponding file
                setSelectedFiles((prevFiles) =>
                    prevFiles.map((f) =>
                        f.fileId === file.name ? { ...f, filePath: base64Image } : f
                    )
                );
            };
            reader.readAsDataURL(file); // Read file as a data URL to get the base64 image
        });
    };

    const handleDeleteSelectFile = (id, index, selectedFiles, setSelectedFiles) => {
        if (id) {
            // Prepopulated case (delete by id)
            setSelectedFiles((prevFiles) => prevFiles.filter(file => file.id !== id));
        } else {
            // New files (delete by index)
            setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
        }
    };

    const deleteItem = (id) => {
        if (id) {
            // Prepopulated case (delete by id)
            deleteFile((prevFiles) => prevFiles.filter(file => file.id !== id));
        } else {
            // New files (delete by index)
            deleteFile((prevFiles) => prevFiles.filter((_, i) => i !== deleteIndex));
        }

        setIsLoading(true);
        deleteArticleImage(id).then((res) => {
            showBottomNotification(t('Article.Successful Deleted'), {
                isFail: false,
            });

            setIsLoading(false);
        }).catch((res) => setIsLoading(false))
        setDeletePopup(false);
    }

    const validateFields = (inputFields, selectedFiles) => {
        const { name, function: func, contact } = inputFields;

        // Check if all fields are filled and trimmed
        const isInputValid = name.trim() !== '' && func.trim() !== '' && contact.trim() !== '';

        // Check if an image is present
        const isImageValid = selectedFiles.length > 0;

        // Fully valid form: either input fields are valid (and possibly no image), or input fields and image are both valid.
        const isFormFilled = isInputValid || (isImageValid && isInputValid);

        // A partially filled form: If some fields are filled but not all (this should not allow submission)
        let isFormPartiallyFilled = false

        if (name.trim() == '' || func.trim() == '' || contact.trim() == '') {
            if (isImageValid) {
                isFormPartiallyFilled = true
            }
        } else if (name.trim() != '' && func.trim() != '' && contact.trim() != '') {
            if (!isImageValid) {
                isFormPartiallyFilled = true
            }
        }

        return { isFormFilled, isFormPartiallyFilled };
    };
    const handleFileUploadSubmit = () => {
        // Validate each section
        const { isFormFilled: form1Filled, isFormPartiallyFilled: form1Partial } = validateFields(inputFields1, selectedFiles1);
        const { isFormFilled: form2Filled, isFormPartiallyFilled: form2Partial } = validateFields(inputFields2, selectedFiles2);
        const { isFormFilled: form3Filled, isFormPartiallyFilled: form3Partial } = validateFields(inputFields3, selectedFiles3);

        // Check if any form is partially filled
        if (form1Partial || form2Partial || form3Partial) {
            alert('Please ensure no form is partially filled. Each form must be either fully filled or left blank.');
            return;
        }

        // Ensure at least one form is fully filled
        if (!form1Filled && !form2Filled && !form3Filled) {
            alert('At least one form must be completely filled.');
            return;
        }

        // Prepare data to submit
        const formData = [];

        // Add form1 data if it's fully filled
        if (form1Filled) {
            const filteredItems = selectedImages1.filter(item => !('id' in item));
            formData.push({
                image: filteredItems ? filteredItems : null, // If no image, set to null
                ...inputFields1, // Add input fields
            });
        }

        // Add form2 data if it's fully filled
        if (form2Filled) {
            const filteredItems = selectedImages2.filter(item => !('id' in item));
            formData.push({
                image: filteredItems ? filteredItems : null,
                ...inputFields2,
            });
        }

        // Add form3 data if it's fully filled
        if (form3Filled) {
            const filteredItems = selectedImages3.filter(item => !('id' in item));
            formData.push({
                image: filteredItems ? filteredItems : null,
                ...inputFields3,
            });
        }

        // Send formData to the backend
        console.log('Submitting data:', formData);

        const payload = new FormData();

        if (formData[0]) {
            if (formData[0].image.length > 0) {
                payload.append("imageOne", formData[0].image[0]);
            }
            payload.append("nameOne", formData[0].name);
            payload.append("functionOne", formData[0].function);
            payload.append("contactOne", formData[0].contact);
            if (updateFlag) {
                payload.append("firstId", formData[0].id);
            }
        }

        if (formData[1]) {
            if (formData[1].image.length > 0) {
                payload.append("imageTwo", formData[1].image[0]);
            }
            payload.append("nameTwo", formData[1].name);
            payload.append("functionTwo", formData[1].function);
            payload.append("contactTwo", formData[1].contact);
            if (updateFlag) {
                payload.append("secondId", formData[1].id);
            }
        }

        if (formData[2]) {
            if (formData[2].image.length > 0) {
                payload.append("image1Three", formData[2].image[0]);
            }
            payload.append("nameThree", formData[2].name);
            payload.append("functionThree", formData[2].function);
            payload.append("contactThree", formData[2].contact);
            if (updateFlag) {
                payload.append("thirdId", formData[2].id);
            }
        }

        if (updateFlag) {

            setIsLoading(true);
            updateThirdArticle(activeKita.kitaId, 3, payload).then((res) => {
                store.dispatch(
                    showBottomNotification(t('Article.Successful Saved'), {
                        isFail: false,
                    }))

                setIsLoading(false);
                goToOverviewPage();
            }).catch((res) => setIsLoading(false))
        } else {

            setIsLoading(true);
            saveThirdArticle(activeKita.kitaId, 3, payload).then((res) => {
                store.dispatch(
                    showBottomNotification(t('Article.Successful Saved'), {
                        isFail: false,
                    }))

                setIsLoading(false);
                goToBackPage();
            }).catch((res) => setIsLoading(false))
        }

    };


    const checksBeforeUploading = () => {
        let showExtensionError = false;
        let sizeError = '';
        let maxFileSize = 200;
        const allowedExtensions = ['image/*', 'image/*'];
        const check = true;
        const allowedFiles = Array.from(selectedFiles)
            .filter(file => {
                if (isMatchesToMediaTypeArray(file.filetype, allowedExtensions, file.filename)) {
                } else {
                    showExtensionError = true;
                    check = false
                }
            })
            .filter(file => {

                if (maxFileSize) {
                    if (file.size / 1024 / 1024 >= maxFileSize) {
                        sizeError = "FileManager.You can't upload file size more than {FILE_SIZE}";
                        check = false
                    } else {
                        check = true
                    }
                }
            });

        if (sizeError) {
            showBottomNotification(t(sizeError).replace('{FILE_SIZE}', `${maxFileSize}MB`), {
                isFail: true,
            });
        }
        return check;
    }

    const goToEditPage = () => {
        setIsShowMode(false);
        setIsEditMode(true);
    }

    const goToOverviewPage = () => {
        setIsShowMode(true);
        setIsEditMode(false);
        firstReload();
        setInputFields1({ name: '', function: '', contact: '', id: '' });
        setInputFields2({ name: '', function: '', contact: '', id: '' });
        setInputFields3({ name: '', function: '', contact: '', id: '' });
    }

    const { location, history } = useReactRouter();
    const goToBackPage = () => {
        history.push('/articles');
    }

    const handleInputChange = (e, setInputFields) => {
        const { name, value } = e.target;
        setInputFields(prevState => ({ ...prevState, [name]: value }));
    };

    const deleteTheEntry = (id) => {
        setIsLoading(true);
        deleteArticle(id).then((res) => {
            store.dispatch(
                showBottomNotification(t('Article.Successfully Deleted'), {
                    isFail: false,
                }))

            setIsLoading(false);
            goToOverviewPage();
        }).catch((res) => setIsLoading(false))
    }

    // Component for rendering each file upload box
    const renderUploadBox = (selectedFiles, setSelectedFiles, setSelectedImages, fileInputRef, inputFields, setInputFields) => (
        <div className={styles.kb_file_upload}>
            <div className={styles.file_upload_box}>
                <input
                    type="file"
                    id="fileupload"
                    className={styles.file_upload_input}
                    ref={fileInputRef} // Reference for event listener
                    onChange={(e) => handleChange(e, setSelectedFiles, setSelectedImages)}
                />
                <span>
                    Drag & Drop oder<span className={styles.file_link}>Wählen Sie Ihre Datei</span>
                </span>
            </div>
            <div className={styles.kb_attach_box}>
                {
                    selectedFiles?.map((data, index) => {
                        const { articleId, id, fileId, filePath, fileDescription } = data;
                        return (
                            <><div className={styles.file_atc_box} key={id}>
                                {fileId?.match(/.(jpg|jpeg|png|gif|svg)$/i) ?
                                    <div className={styles.file_image}> <img src={filePath} alt="" /></div> :
                                    <div className={styles.file_image}><i className="far fa-file-alt"></i></div>}
                                <div className={styles.file_detail}>
                                    <h6>{fileId}</h6>
                                    <p></p>
                                    <p></p>
                                    <div className={styles.file_actions}>
                                        <button type="button" className={styles.file_action_btn} onClick={() => handleDeleteSelectFile(id, index, selectedFiles, setSelectedFiles)}>Löschen</button>
                                    </div>
                                </div>
                            </div><div>
                                    <Button className={styles.editButton} onClick={() => deleteTheEntry(articleId)}>
                                        den Eintrag löschen
                                    </Button>
                                </div></>
                        )
                    })
                }
            </div>
            <div className={styles.inputFields}>
                <div className={styles.inputGroup}>
                    <label className={styles.label}>
                        Vorname, Name <span className={styles.asterisk}>*</span>
                    </label>
                    <input
                        type="text"
                        className={styles.input}
                        name="name"
                        value={inputFields.name}
                        onChange={(e) => handleInputChange(e, setInputFields)}
                    />
                </div>

                <div className={styles.inputGroup}>
                    <label className={styles.label}>
                        Function <span className={styles.asterisk}>*</span>
                    </label>
                    <input
                        type="text"
                        className={styles.input}
                        name="function"
                        value={inputFields.function}
                        onChange={(e) => handleInputChange(e, setInputFields)}
                    />
                </div>

                <div className={styles.inputGroup}>
                    <label className={styles.label}>
                        Kontaktdaten <span className={styles.asterisk}>*</span>
                    </label>
                    <input
                        type="text"
                        className={styles.input}
                        name="contact"
                        value={inputFields.contact}
                        onChange={(e) => handleInputChange(e, setInputFields)}
                    />
                </div>
            </div>
        </div>
    );

    useEffect(() => {
        // Cleanup function to remove event listener when component unmounts
        return () => {
            if (fileInputRef.current) {
                fileInputRef.current.removeEventListener("change", handleChange);
            }
        };
    }, [fileInputRef.current, handleChange]); // Dependency array

    return (
        <KitaPanelWrapper>

            <Wrapper>
                <div className={styles.fileupload_view}>
                    <div className="col-md-6">
                        <div className={styles.card}>
                            {isLoading ? (<Loader />) : (<div className={styles.card_body}>

                                <div className={styles.first_article_heading}>
                                    Unser Vorstand
                                </div>
                                {isEditMode && (<div className={styles.kb_data_box}>
                                    <form onSubmit={handleFileUploadSubmit}>
                                        {renderUploadBox(selectedFiles1, setSelectedFiles1, setSelectedImages1, fileInputRef1, inputFields1, setInputFields1)}
                                        {renderUploadBox(selectedFiles2, setSelectedFiles2, setSelectedImages2, fileInputRef2, inputFields2, setInputFields2)}
                                        {renderUploadBox(selectedFiles3, setSelectedFiles3, setSelectedImages3, fileInputRef3, inputFields3, setInputFields3)}
                                        {/* Input Fields below each upload box */}

                                        <div className={styles.kb_buttons_box}>
                                            <Button type="submit" className={styles.submitButton} onClick={() => handleFileUploadSubmit()}>Hochladen</Button>

                                            <Button onClick={() => goToBackPage()} className={styles.backButton} type='primary'>
                                                {t('Zurück zur Hauptseite')}
                                            </Button>
                                        </div>
                                    </form>
                                </div>)}
                                {isShowMode && (
                                    <><>
                                        {!noArticle ? (<div>
                                            {articleData?.map((article, index) => (
                                                <div className={styles.box} key={article.id}>
                                                    <div className={styles.profileContainer}>
                                                        <div className={styles.fileUploadBox}>
                                                            {/* Check if image exists */}
                                                            {article.images && article.images.length > 0 && (
                                                                <img
                                                                    src={article.images[0].filePath}
                                                                    alt={`Profile ${article.firstName}`}
                                                                />
                                                            )}
                                                        </div>
                                                        <div className={styles.inputFields}>
                                                            <label>
                                                                <span className={styles.labelSpan}>Vorname, Name</span>
                                                                <p>{article.firstName || 'N/A'}</p>
                                                            </label>
                                                            <label>
                                                                <span className={styles.labelSpan}>Function</span>
                                                                <p>{article.functionText || 'N/A'}</p>
                                                            </label>
                                                            <label>
                                                                <span className={styles.labelSpan}>Kontaktdaten</span>
                                                                <p>{article.contactDate || 'N/A'}</p>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>) : (
                                            <div className={styles.noArticle}>
                                                <p>{t('Article.No Articles to be shown')}</p>
                                            </div>
                                        )}
                                        <p>{articleDescription}</p></>
                                        <Button onClick={() => goToEditPage()} className={styles.editButton} type='primary'>
                                            {t('Artikel bearbeiten')}
                                        </Button>
                                        <Button onClick={() => goToBackPage()} className={styles.backButton} type='primary'>
                                            {t('Zurück zur Hauptseite')}
                                        </Button></>
                                )}
                            </div>)}
                        </div>
                    </div>
                </div>
                {deletePopup && (<Popup
                    closePopup={() => setDeletePopup(false)}
                    isOpened
                    footer={(
                        <div className={styles.buttonsContainer}>
                            <div className={styles.buttonSubmit}>
                                <Button onClick={() => deleteItem(deleteID)} type='primary'>
                                    {t('AccountSettings.AbsenceSettings.Yes')}
                                </Button>
                            </div>
                            <div className={styles.buttonSubmit}>
                                <Button onClick={() => setDeletePopup(false)} type='primary'>
                                    {t('AccountSettings.AbsenceSettings.No')}
                                </Button>
                            </div>
                        </div>
                    )
                    }
                >
                    <div className={styles.containerEnable}>
                        <h2>Sind Sie sicher, dass Sie die Bilder löschen möchten?</h2>
                    </div>
                </Popup>)}
            </Wrapper>
        </KitaPanelWrapper >

    );
};

export default connect(state => ({
    activeKita: state.kitaReducer.activeKita,
})
)(ThirdArticle);