export const parseTimeObjectToDate = timeObject => {
  return new Date(0, 0, 0, timeObject.hour, timeObject.minute, timeObject.second);
};

export const getWorkingTimeByDay = employeeInfo => {
  // value in minutes
  const workingTimeByDay = {
    sunday: 0,
    monday: 0,
    tuesday: 0,
    wednesday: 0,
    thursday: 0,
    friday: 0,
    saturday: 0,
  };

  // check if time is set and therefore employee works that day
  const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  if (employeeInfo) {
    daysOfWeek.forEach(day => {
      // if working time is set, add it to
      if (employeeInfo[`${day}StartTime`]) {
        const startTime = parseTimeObjectToDate(employeeInfo[`${day}StartTime`]);
        const endTime = parseTimeObjectToDate(employeeInfo[`${day}EndTime`]);
        const differenceInMinutes = (endTime - startTime) / (60 * 1000);
        workingTimeByDay[day] += differenceInMinutes;
      }
      if (employeeInfo[`${day}SecondStartTime`]) {
        const startTime = parseTimeObjectToDate(employeeInfo[`${day}SecondStartTime`]);
        const endTime = parseTimeObjectToDate(employeeInfo[`${day}SecondEndTime`]);
        const differenceInMinutes = (endTime - startTime) / (60 * 1000);
        workingTimeByDay[day] += differenceInMinutes;
      }
      if (employeeInfo[`${day}AvailableStartTime`]) {
        const startTime = parseTimeObjectToDate(employeeInfo[`${day}AvailableStartTime`]);
        const endTime = parseTimeObjectToDate(employeeInfo[`${day}AvailableEndTime`]);
        const differenceInMinutes = (endTime - startTime) / (60 * 1000);
        workingTimeByDay[day] += differenceInMinutes;
      }
    });
  }
  return workingTimeByDay;
};

export const getWorkingTimeForDateInMin = (employeeInfo, date) => {
  const dayOfWeek = date.toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase();
  return getWorkingTimeByDay(employeeInfo)[dayOfWeek];
};

export const formatMinutesToString = minutes => {
  if (minutes === 0) return '0 min';
  const naturalMinutes = Math.abs(minutes);
  const min = naturalMinutes % 60;
  const hours = Math.floor(naturalMinutes / 60);

  if (minutes < 60 && minutes > -60) return `${min}min`;
  else if (min == 0) return `${hours}h`;
  else return `${hours}h ${min}min`;
};

export const formatNetWorkingTime = minutes => {
  if (minutes === 0) return '0 min';
  const formattedMinutes = formatMinutesToString(minutes);
  return minutes < 0 ? `- ${formattedMinutes}` : `+ ${formattedMinutes}`;
};

export const formatMinutesToTimeString = minutes => {
  const isNegative = minutes < 0;
  const naturalMinutes = Math.abs(Math.round(minutes));
  const hours = Math.floor(naturalMinutes / 60);
  const mins = Math.floor(naturalMinutes % 60);
  const formattedTime = `${isNegative ? '-' : ''}${hours
    .toString()
    .padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
  return formattedTime;
};

// group checkInOuts by date and also add the working time for the day when employeeInfo is passed
export const groupCheckInOutsByDate = (employeesCheckInOut, employeeInfo) => {
  const groupedDates = employeesCheckInOut.reduce((result, entryObj) => {
    const date = new Date(entryObj.actionDate);
    const dateString = date.toLocaleDateString('de-DE');

    if (!result[dateString]) {
      result[dateString] = [];
    }
    result[dateString].push(entryObj);
    return result;
  }, {});

  // sort all entrys by date ascending
  Object.entries(groupedDates).map(([date, entries]) => {
    entries.sort((a, b) => new Date(a.actionDate) - new Date(b.actionDate));
  });

  // return wehn no employeeInfo is passed
  if (employeeInfo == null || employeeInfo == undefined) {
    return Object.entries(groupedDates).map(([date, entries]) => {
      return { date, entries };
    });
  }

  const workingTimeByDay = getWorkingTimeByDay(employeeInfo);

  const result = Object.entries(groupedDates).map(([date, entries]) => {
    // get the necessary working time for the week day of employee
    const checkInOutDate = new Date(entries[0].actionDate);
    const dayOfWeek = checkInOutDate.toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase();
    const workingTime = workingTimeByDay[dayOfWeek];

    return {
      date,
      entries,
      workingTime,
    };
  });

  return result;
};

export const getTotalManualBreakTimeInMilis = checkInOutsOfDay => {
  let breakTime = 0;
  checkInOutsOfDay.sort((a, b) => new Date(a.actionDate) - new Date(b.actionDate));
  checkInOutsOfDay.forEach((entry, index, array) => {
    if (index !== 0 && array[index - 1].checkInOutCategory === 'BREAK') {
      const dateBreakStart = new Date(array[index - 1].actionDate);
      const dateBreakEnd = new Date(entry.actionDate);
      breakTime += dateBreakEnd - dateBreakStart;
    }
  });
  return breakTime;
};

// important: no breaks are included
export const getTotalCheckoutTimeInMilis = checkInOutsOfDay => {
  let checkoutTime = 0;
  checkInOutsOfDay.sort((a, b) => new Date(a.actionDate) - new Date(b.actionDate));
  checkInOutsOfDay.forEach((entry, index, array) => {
    if (
      index !== 0 &&
      array[index - 1].checkInOutCategory !== 'BREAK' &&
      array[index - 1].checkInOutType === 'CHECKOUT'
    ) {
      const dateBreakStart = new Date(array[index - 1].actionDate);
      const dateBreakEnd = new Date(entry.actionDate);
      checkoutTime += dateBreakEnd - dateBreakStart;
    }
  });
  return checkoutTime;
};

export const getTotalTimeWorkedInMilis = checkInOutsOfDay => {
  let timeWorked = 0;
  checkInOutsOfDay.sort((a, b) => new Date(a.actionDate) - new Date(b.actionDate));
  checkInOutsOfDay.forEach((_, index, array) => {
    // only calculate every second iteration so index is at checkout
    if (index % 2 !== 1) {
      return;
    }
    const checkIn = new Date(array[index - 1].actionDate);
    const checkOut = new Date(array[index].actionDate);
    timeWorked += checkOut - checkIn;
  });
  return timeWorked;
};

export const getTotalTimeOfAbsenceCheckInOutsInMilis = checkInOutsOfDay => {
  const absenceCheckInOuts = checkInOutsOfDay.filter(
    checkInOut => checkInOut.checkInOutCategory === 'ABSENCE',
  );
  return getTotalTimeWorkedInMilis(absenceCheckInOuts);
};

// milliseconds to format like 03:30 or 03:30:20
export const formatMilisToTimeString = (timeMs, withSeconds = false, padded = false) => {
  // get neutral time in ms
  const isNegative = timeMs < 0;
  const absoluteTimeMs = Math.abs(timeMs);

  const formatDigits = digits => {
    return `${digits.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}`;
  };

  const seconds = formatDigits(Math.round(absoluteTimeMs / 1000) % 60);
  const minutes = withSeconds
    ? formatDigits(Math.floor(absoluteTimeMs / (1000 * 60)) % 60)
    : formatDigits(Math.round(absoluteTimeMs / (1000 * 60)) % 60);
  const hours = formatDigits(Math.floor(absoluteTimeMs / (1000 * 60 * 60)));

  let result = '';
  if (withSeconds) {
    result = `${isNegative ? '-' : ''} ${hours} : ${minutes} : ${seconds}`;
  } else {
    result = `${isNegative ? '-' : ''} ${hours} : ${minutes}`;
  }

  if (!padded) {
    result = result.replace(/ /g, '');
  }

  return result;
};

// get duration of legal break time in ms
export const getLegalBreakTimeFromWorktimInMin = workingTimeInMin => {
  const nineHours = 540;
  const sixHours = 360;

  if (workingTimeInMin >= nineHours) {
    return workingTimeInMin - 45 < nineHours ? workingTimeInMin - nineHours + 30 : 45;
  } else if (workingTimeInMin > 360) {
    return workingTimeInMin - 30 < sixHours ? workingTimeInMin - sixHours : 30;
  } else {
    return 0;
  }
};

export const parseGermanDate = date => {
  const [day, month, year] = date.split('.').map(Number);
  return new Date(year, month - 1, day);
};
