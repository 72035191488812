import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SignatureCanvas from 'react-signature-canvas';

import {
  postGfzAddDays,
  getGfzAddDays,
  getAllKidsFromSelectedKita,
  getAllOwnKidsFromSelectedKita,
  declineGfzAddDays
} from "library/api/observationSheet";
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import Button from 'library/common/commonComponents/Buttons/Button';
import Input from 'library/common/commonComponents/Inputs/Input';
import Select from 'library/common/commonComponents/Inputs/Select';
import RadioButton from 'library/common/commonComponents/RadioButton';
import { createPDFAddDays } from 'library/utilities/files';
import { mapAddDayTypeToPrice } from 'library/utilities/GFZ';
import { removeWhiteBackgroundSignature } from 'library/utilities/signature';
import store from 'main/store/configureStore';

import gfzLogo from 'resources/images/gfz/gfz_logo.png';

export default function AddDays({ isAdmin, isEmployee, userId, kitaName, kidId, formId, setActiveTab }) {
  const [progress, setProgress] = useState(0);
  const [selectedKid, setSelectedKid] = useState([]);
  const [kidsList, setKidsList] = useState([]);
  const [formDisabled, setFormDisabled] = useState(false);
  const [isExistingForKid, setIsExistingForKid] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);

  const [addDays, setAddDays] = useState({
    kitaName: '',
    groupName: '',
    date: new Date().toISOString().split('T')[0],
    additionalDays: [],
    additionalHours: [],
    kidId: null,
    parentSignature: null,
    parentSignatureDate: new Date().toISOString().split('T')[0],
    kitaSignature: null,
    kitaSignatureDate: new Date().toISOString().split('T')[0],
  });

  const sigCanvasParent = useRef();
  const sigCanvasKita = useRef();
  const isAdminOrEmployee = isAdmin || isEmployee;
  const noKidSelected = Array.isArray(selectedKid) && selectedKid.length === 0;

  const user = useSelector(state => state.userReducer);

  const getKitaKidsFunction = isAdminOrEmployee
    ? getAllKidsFromSelectedKita
    : getAllOwnKidsFromSelectedKita;
  const { t } = useTranslation();

  const [isParentOfKid, setIsParentOfKid] = useState(false);

  function getModifiedDateSelectionDate() {
    var date = new Date();
    date.setFullYear(date.getFullYear() - 1);
    date.setDate(date.getDate() + 1);
    return date.toISOString().split('T')[0];
  }

  useEffect(() => {
    setIsParentOfKid(
      selectedKid &&
      selectedKid !== [] &&
      user.kids &&
      user.kids.find(k => k.id === selectedKid.id),
    );
    sigCanvasParent.current.clear();
    sigCanvasKita.current.clear();
  }, [selectedKid]);

  useEffect(() => {
    if (isParentOfKid) {
      sigCanvasParent.current.on();
    } else {
      sigCanvasParent.current.off();
    }

    if (isAdmin) {
      sigCanvasKita.current.on();
    } else {
      sigCanvasKita.current.off();
    }
  }, [isParentOfKid]);

  useEffect(() => {
    getKitaKidsFunction().then(data => {
      const unformattedKidsList = data.data;
      const formattedKidsList = unformattedKidsList.map(obj => {
        return {
          label: obj.firstName + ' ' + obj.lastName,
          id: isAdminOrEmployee ? obj.kidId : obj.id,
          portfolioGroupId: isAdminOrEmployee ? obj.portfolioGroupId : obj.groups.id,
          kidGroup: obj.kidGroup,
        };
      });
      setKidsList(formattedKidsList);
    });
    if (!isAdmin && !isParentOfKid) {
      setFormDisabled(true);
    }
  }, []);

  const loadGfzAddDaysForKid = (kid, selectedFormId) => {
    if (!isAdmin && !isParentOfKid) {
      setFormDisabled(false);
    }
    setIsCompleted(false);
    setIsExistingForKid(false);
    getGfzAddDays(kid.id, selectedFormId).then(res => {
      if (res.data != null) {
        setIsExistingForKid(true);
        setAddDays({
          ...res.data,
          kitaSignatureDate:
            res.data.kitaSignatureDate === null ? '' : res.data.kitaSignatureDate,
          parentSignatureDate:
            res.data.parentSignatureDate === null ? '' : res.data.parentSignatureDate,
        });

        if (res.data.parentSignature !== null) {
          sigCanvasParent.current.fromDataURL(res.data.parentSignature);
          setFormDisabled(true);
        }
        if (res.data.kitaSignature !== null) {
          sigCanvasKita.current.fromDataURL(res.data.kitaSignature);
          setFormDisabled(true);
        }
        if (res.data.parentSignature !== null && res.data.kitaSignature !== null) {
          setIsCompleted(true);
        }
      } else {
        setAddDays({
          kitaName: kitaName ? kitaName : '',
          groupName: kid.kidGroup ? kid.kidGroup.groupName : '',
          date: new Date().toISOString().split('T')[0],
          additionalDays: [],
          additionalHours: [],
          kidId: kid.id,
          parentSignature: null,
          parentSignatureDate: new Date().toISOString().split('T')[0],
          kitaSignature: null,
          kitaSignatureDate: new Date().toISOString().split('T')[0],
        });
      }
    });
  };

  useEffect(() => {
    if (
      kidId != null && typeof kidId !== 'undefined' &&
      formId != null && typeof formId !== 'undefined' &&
      selectedKid.length === 0
    ) {
      const kidToSelect = kidsList.filter(obj => obj.id === kidId);
      if (kidToSelect.length !== 1) {
        return;
      }

      setSelectedKid(kidToSelect[0]);
      loadGfzAddDaysForKid(kidToSelect[0], formId);
    }
  }, [kidId, formId, kidsList]);

  function resetForm() {
    setAddDays({
      kitaName: '',
      groupName: '',
      date: new Date().toISOString().split('T')[0],
      additionalDays: [],
      additionalHours: [],
      kidId: null,
      parentSignature: null,
      parentSignatureDate: new Date().toISOString().split('T')[0],
      kitaSignature: null,
      kitaSignatureDate: new Date().toISOString().split('T')[0],
    });
    setSelectedKid([]);
    sigCanvasParent.current.clear();
    sigCanvasKita.current.clear();
  }

  const renderAdditionalDaysRow = (item, index) => {
    return (
      <>
        <tr>
          <td>
            <Input
              type='date'
              value={item.date}
              disabled={formDisabled}
              min={getModifiedDateSelectionDate()}
              onChange={({ target }) => {
                setAddDays(prev => ({
                  ...prev,
                  additionalDays: prev.additionalDays.map((i, ind) => {
                    if (ind === index) {
                      return {
                        ...i,
                        date: target.value,
                      };
                    }

                    return i;
                  }),
                }));
              }}
            />
          </td>
          <td />
          <td />
          <td />
          <td />
          <td />
          <td />
          <td />
        </tr>
        <tr>
          <td>{t('GFZForms.AddDayForBaby')}</td>
          <td>
            <RadioButton
              checked={item.type === 'wholeDay'}
              disabled={formDisabled}
              onClick={() => {
                setAddDays(prev => ({
                  ...prev,
                  additionalDays: prev.additionalDays.map((i, ind) => {
                    if (ind === index) {
                      return {
                        ...i,
                        type: 'wholeDay',
                      };
                    }

                    return i;
                  }),
                }));
              }}
            />
          </td>
          <td>
            <RadioButton
              checked={item.type === 'morningWithFood'}
              disabled={formDisabled}
              onClick={() => {
                setAddDays(prev => ({
                  ...prev,
                  additionalDays: prev.additionalDays.map((i, ind) => {
                    if (ind === index) {
                      return {
                        ...i,
                        type: 'morningWithFood',
                      };
                    }

                    return i;
                  }),
                }));
              }}
            />
          </td>
          <td>
            <RadioButton
              checked={item.type === 'afternoonWithoutFood'}
              disabled={formDisabled}
              onClick={() => {
                setAddDays(prev => ({
                  ...prev,
                  additionalDays: prev.additionalDays.map((i, ind) => {
                    if (ind === index) {
                      return {
                        ...i,
                        type: 'afternoonWithoutFood',
                      };
                    }

                    return i;
                  }),
                }));
              }}
            />
          </td>
          <td>
            <RadioButton
              checked={item.type === 'morningWithoutFood'}
              disabled={formDisabled}
              onClick={() => {
                setAddDays(prev => ({
                  ...prev,
                  additionalDays: prev.additionalDays.map((i, ind) => {
                    if (ind === index) {
                      return {
                        ...i,
                        type: 'morningWithoutFood',
                      };
                    }

                    return i;
                  }),
                }));
              }}
            />
          </td>
          <td>
            <RadioButton
              checked={item.type === 'afternoonWithFood'}
              disabled={formDisabled}
              onClick={() => {
                setAddDays(prev => ({
                  ...prev,
                  additionalDays: prev.additionalDays.map((i, ind) => {
                    if (ind === index) {
                      return {
                        ...i,
                        type: 'afternoonWithFood',
                      };
                    }

                    return i;
                  }),
                }));
              }}
            />
          </td>
          {!item.type.endsWith('Alt') ? (
            <td>{mapAddDayTypeToPrice(item.type)} Fr.</td>
          ) : (
            <td></td>
          )}
          <td>
            <Button
              onClick={() => {
                setAddDays(prev => ({
                  ...prev,
                  additionalDays: prev.additionalDays.filter((i, ind) => ind !== index),
                }));
              }}
            >
              <i className='fa fa-minus' />
            </Button>
          </td>
        </tr>
        <tr>
          <td>{t('GFZForms.AddDayForChilds')}</td>
          <td>
            <RadioButton
              checked={item.type === 'wholeDayAlt'}
              disabled={formDisabled}
              onClick={() => {
                setAddDays(prev => ({
                  ...prev,
                  additionalDays: prev.additionalDays.map((i, ind) => {
                    if (ind === index) {
                      return {
                        ...i,
                        type: 'wholeDayAlt',
                      };
                    }

                    return i;
                  }),
                }));
              }}
            />
          </td>
          <td>
            <RadioButton
              checked={item.type === 'morningWithFoodAlt'}
              disabled={formDisabled}
              onClick={() => {
                setAddDays(prev => ({
                  ...prev,
                  additionalDays: prev.additionalDays.map((i, ind) => {
                    if (ind === index) {
                      return {
                        ...i,
                        type: 'morningWithFoodAlt',
                      };
                    }

                    return i;
                  }),
                }));
              }}
            />
          </td>
          <td>
            <RadioButton
              checked={item.type === 'afternoonWithoutFoodAlt'}
              disabled={formDisabled}
              onClick={() => {
                setAddDays(prev => ({
                  ...prev,
                  additionalDays: prev.additionalDays.map((i, ind) => {
                    if (ind === index) {
                      return {
                        ...i,
                        type: 'afternoonWithoutFoodAlt',
                      };
                    }

                    return i;
                  }),
                }));
              }}
            />
          </td>
          <td>
            <RadioButton
              checked={item.type === 'morningWithoutFoodAlt'}
              disabled={formDisabled}
              onClick={() => {
                setAddDays(prev => ({
                  ...prev,
                  additionalDays: prev.additionalDays.map((i, ind) => {
                    if (ind === index) {
                      return {
                        ...i,
                        type: 'morningWithoutFoodAlt',
                      };
                    }

                    return i;
                  }),
                }));
              }}
            />
          </td>
          <td>
            <RadioButton
              checked={item.type === 'afternoonWithFoodAlt'}
              disabled={formDisabled}
              onClick={() => {
                setAddDays(prev => ({
                  ...prev,
                  additionalDays: prev.additionalDays.map((i, ind) => {
                    if (ind === index) {
                      return {
                        ...i,
                        type: 'afternoonWithFoodAlt',
                      };
                    }

                    return i;
                  }),
                }));
              }}
            />
          </td>
          {item.type.endsWith('Alt') ? (
            <td>{mapAddDayTypeToPrice(item.type)} Fr.</td>
          ) : (
            <td></td>
          )}
          <td />
        </tr>
      </>
    );
  };

  const renderAdditionalHoursRow = (item, index) => {
    return (
      <tr>
        <td>
          <Input
            type='date'
            value={item.date}
            disabled={formDisabled}
            min={getModifiedDateSelectionDate()}
            onChange={({ target }) => {
              setAddDays(prev => ({
                ...prev,
                additionalHours: prev.additionalHours.map((i, ind) => {
                  if (ind === index) {
                    return {
                      ...i,
                      date: target.value,
                    };
                  }

                  return i;
                }),
              }));
            }}
          />
        </td>
        <td>
          <Input
            type='text'
            maxLength={50}
            value={item.fromTime}
            disabled={formDisabled}
            onChange={({ target }) => {
              setAddDays(prev => ({
                ...prev,
                additionalHours: prev.additionalHours.map((i, ind) => {
                  if (ind === index) {
                    return {
                      ...i,
                      fromTime: target.value,
                    };
                  }

                  return i;
                }),
              }));
            }}
          />
        </td>
        <td>
          <Input
            type='text'
            maxLength={50}
            value={item.toTime}
            disabled={formDisabled}
            onChange={({ target }) => {
              setAddDays(prev => ({
                ...prev,
                additionalHours: prev.additionalHours.map((i, ind) => {
                  if (ind === index) {
                    return {
                      ...i,
                      toTime: target.value,
                    };
                  }

                  return i;
                }),
              }));
            }}
          />
        </td>
        <td>
          <Input
            type='text'
            maxLength={50}
            value={item.total}
            disabled={formDisabled}
            onChange={({ target }) => {
              setAddDays(prev => ({
                ...prev,
                additionalHours: prev.additionalHours.map((i, ind) => {
                  if (ind === index) {
                    return {
                      ...i,
                      total: target.value,
                    };
                  }

                  return i;
                }),
              }));
            }}
          />
        </td>
        <td>
          <Input
            type='text'
            maxLength={50}
            value={item.reason}
            disabled={formDisabled}
            onChange={({ target }) => {
              setAddDays(prev => ({
                ...prev,
                additionalHours: prev.additionalHours.map((i, ind) => {
                  if (ind === index) {
                    return {
                      ...i,
                      reason: target.value,
                    };
                  }

                  return i;
                }),
              }));
            }}
          />
        </td>
        <td>
          <Button
            onClick={() => {
              setAddDays(prev => ({
                ...prev,
                additionalHours: prev.additionalHours.filter((i, ind) => ind !== index),
              }));
            }}
          >
            <i className='fa fa-minus' />
          </Button>
        </td>
      </tr>
    );
  };

  const calcHoursSum = () => {
    return addDays.additionalHours.reduce((acc, x) => acc + parseInt(x.total), 0);
  };

  const calcTotalSum = () => {
    return (
      calcHoursSum() * 20 +
      addDays.additionalDays.reduce((acc, x) => acc + mapAddDayTypeToPrice(x.type), 0)
    );
  };

  return (
    <>
      <img src={gfzLogo} style={{ width: '100%', maxWidth: '300px', height: 'auto' }} alt='' />
      <br />
      <br />
      <h2>{t('GFZForms.AddDaysAdditionalDaysHours')}</h2>
      <br />
      <table>
        <tbody>
          <tr>
            <td>{t('GFZForms.AddDaysKidName')}</td>
            <td>
              <Select
                options={kidsList}
                onSelect={e => {
                  setSelectedKid(e);
                  loadGfzAddDaysForKid(e, -1);
                }}
                selected={selectedKid}
                style={{ zIndex: '10000000000' }}
              />
            </td>
          </tr>
          <tr>
            <td>{t('GFZForms.AddDaysKita')}</td>
            <td>
              <Input
                type='text'
                maxLength={50}
                value={addDays.kitaName}
                disabled={formDisabled}
                onChange={({ target }) => {
                  setAddDays(prev => ({
                    ...prev,
                    kitaName: target.value,
                  }));
                }}
              />
            </td>
          </tr>
          <tr>
            <td>{t('GFZForms.AddDaysGroup')}</td>
            <td>
              <Input
                type='text'
                maxLength={50}
                value={addDays.groupName}
                disabled={formDisabled}
                onChange={({ target }) => {
                  setAddDays(prev => ({
                    ...prev,
                    groupName: target.value,
                  }));
                }}
              />
            </td>
          </tr>
          <tr>
            <td>{t('GFZForms.AddDaysDate')}</td>
            <td>
              <Input
                type='date'
                value={addDays.date}
                disabled={formDisabled}
                min={new Date().toISOString().split('T')[0]}
                onChange={({ target }) => {
                  setAddDays(prev => ({
                    ...prev,
                    date: target.value,
                  }));
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <br />
      <span style={{ fontWeight: 'bold' }}>{t('GFZForms.AddDaysAdditionalDays')}</span>
      <table>
        <tbody>
          <tr>
            <th>{t('GFZForms.AddDaysDate')}</th>
            <th>{t('GFZForms.AddDaysWholeDay')}</th>
            <th>{t('GFZForms.AddDaysVMwithFood')}</th>
            <th>{t('GFZForms.AddDaysNMwithoutFood')}</th>
            <th>{t('GFZForms.AddDaysVMwithoutFood')}</th>
            <th>{t('GFZForms.AddDaysNMwithFood')}</th>
            <th>{t('GFZForms.AddDaysPrice')}</th>
            <th />
          </tr>
          {addDays.additionalDays.map((item, index) => renderAdditionalDaysRow(item, index))}
        </tbody>
      </table>
      {addDays.additionalDays.length > 0 && (
        <>
          <br />
          <p>
            <span style={{ fontWeight: 'bold' }}>{t('GFZForms.Sum')}</span>{' '}
            {addDays.additionalDays.reduce((acc, x) => acc + mapAddDayTypeToPrice(x.type), 0)} Fr.
          </p>
        </>
      )}

      <br />
      <Button
        onClick={() => {
          setAddDays(prev => ({
            ...prev,
            additionalDays: [
              ...prev.additionalDays,
              {
                date: '',
                type: 'wholeDay',
              },
            ],
          }));
        }}
      >
        {t('GFZForms.AddDaysAddTag')}
      </Button>
      <br />
      <br />
      <br />
      <span style={{ fontWeight: 'bold' }}>{t('GFZForms.AddDaysAdditionalDaysPricePerHour')}</span>
      <table>
        <tbody>
          <tr>
            <th>{t('GFZForms.AddDaysDate')}</th>
            <th>{t('GFZForms.AddDaysFrom')}</th>
            <th>{t('GFZForms.AddDaysUntil')}</th>
            <th>{t('GFZForms.AddDaysTotal')}</th>
            <th>{t('GFZForms.AddDaysReason')}</th>
            <th />
          </tr>
          {addDays.additionalHours.map((item, index) => renderAdditionalHoursRow(item, index))}
        </tbody>
      </table>
      {addDays.additionalHours.length > 0 && !isNaN(calcHoursSum()) && (
        <>
          <br />
          <p>
            <span style={{ fontWeight: 'bold' }}>{t('GFZForms.Sum')}</span> {calcHoursSum()} x 20
            Fr. = {calcHoursSum() * 20} Fr.
          </p>
        </>
      )}
      <br />
      <Button
        onClick={() => {
          setAddDays(prev => ({
            ...prev,
            additionalHours: [
              ...prev.additionalHours,
              {
                date: '',
                fromTime: '',
                toTime: '',
                total: '',
                reason: '',
              },
            ],
          }));
        }}
      >
        {t('GFZForms.AddDaysExtraHour')}
      </Button>
      {!isNaN(calcTotalSum()) && (
        <>
          <br />
          <br />
          <br />
          <p>
            <span style={{ fontWeight: 'bold' }}>{t('GFZForms.TotalSum')}</span> {calcTotalSum()}{' '}
            Fr.
          </p>
        </>
      )}
      <br />
      <br />
      <br />
      {t('GFZForms.AddDaysForwardingDocs')}
      <br />
      <br />
      <div
        style={{ width: '285px', border: '1px solid', borderColor: '#ededed', borderRadius: '5px' }}
      >
        <div style={{ fontWeight: '600', backgroundColor: '#ededed', padding: '5px' }}>
          {t('GfzForms.SignatureParents')}{' '}
          <i
            style={{ float: 'right', cursor: 'pointer' }}
            onClick={() => {
              sigCanvasParent.current.clear();
            }}
            className='fa fa-eraser'
          />
          <br />
          <br />
        </div>
        <div style={{ padding: '5px' }}>
          <SignatureCanvas
            ref={sigCanvasParent}
            penColor='black'
            canvasProps={{ width: 275, height: 91, className: 'sigCanvas' }}
          />
        </div>
        <div>
          <Input
            type='date'
            value={addDays.parentSignatureDate}
            min={new Date().toISOString().split('T')[0]}
            disabled
            onChange={({ target }) => {
              setAddDays(prev => ({
                ...prev,
                parentSignatureDate: target.value,
              }));
            }}
          />
        </div>
      </div>
      <br />
      <div
        style={{ width: '285px', border: '1px solid', borderColor: '#ededed', borderRadius: '5px' }}
      >
        <div style={{ fontWeight: '600', backgroundColor: '#ededed', padding: '5px' }}>
          {t('GfzForms.SignatureKitaManagement')}{' '}
          <i
            style={{ float: 'right', cursor: 'pointer' }}
            onClick={() => {
              sigCanvasKita.current.clear();
            }}
            className='fa fa-eraser'
          />
          <br />
          <br />
        </div>
        <div style={{ padding: '5px' }}>
          <SignatureCanvas
            ref={sigCanvasKita}
            penColor='black'
            canvasProps={{ width: 275, height: 91, className: 'sigCanvas' }}
          />
        </div>
        <div>
          <Input
            type='date'
            value={addDays.kitaSignatureDate}
            min={new Date().toISOString().split('T')[0]}
            disabled
            onChange={({ target }) => {
              setAddDays(prev => ({
                ...prev,
                kitaSignatureDate: target.value,
              }));
            }}
          />
        </div>
      </div>
      <br />
      {noKidSelected && <div style={{ color: "red" }}><i className='fa fa-exclamation-triangle' /> {t('GFZForms.Please select a kid in the dropdown')}<br /></div>}
      <Button
        disabled={isCompleted || (!isAdmin && !isParentOfKid) || noKidSelected}
        onClick={() => {
          const parentSigned = !sigCanvasParent.current.isEmpty();
          const kitaSigned = !sigCanvasKita.current.isEmpty();

          let pdf = null;
          if (parentSigned && kitaSigned) {
            const pdfSaveData = {
              addDays: addDays,
              selectedKid: selectedKid,
              setProgress: setProgress,
              userId: userId,
              parentSignature: addDays.parentSignature !== null ? addDays.parentSignature : removeWhiteBackgroundSignature(
                sigCanvasParent.current.getCanvas(),
              ).toDataURL(),
              kitaSignature: addDays.kitaSignature !== null ? addDays.kitaSignature : removeWhiteBackgroundSignature(
                sigCanvasKita.current.getCanvas(),
              ).toDataURL(),
            };
            pdf = createPDFAddDays(pdfSaveData, t);
          }

          postGfzAddDays({
            ...addDays,
            parentSignature: sigCanvasParent.current.isEmpty()
              ? null
              : sigCanvasParent.current.toDataURL(),
            kitaSignature: sigCanvasKita.current.isEmpty()
              ? null
              : sigCanvasKita.current.toDataURL(),
          }, pdf).then((res) => {
            store.dispatch(showBottomNotification('Gespeichert', { isFail: false }));
            resetForm();
            setActiveTab(0);
          });
        }}
        type='primary'
      >
        {t('GFZForms.AddDaysSave')}
      </Button>
      &nbsp;
      <Button
        disabled={isCompleted || (!isAdmin && !isParentOfKid)}
        onClick={() => {
          sigCanvasKita.current.clear();
          sigCanvasParent.current.clear();
          setFormDisabled(false);
        }}
      >
        {t('GFZForms.EditFormAndResetSignatures')}
      </Button>
      &nbsp;
      {(isParentOfKid || isAdmin) && isExistingForKid && !isCompleted && (
        <Button
          onClick={() => {
            declineGfzAddDays(selectedKid.id, addDays.id).then(() => {
              store.dispatch(showBottomNotification(t('GfzForms.Refused'), { isFail: false }));
              resetForm();
            });
          }}
        >
          {t('GFZForms.Decline')}
        </Button>
      )}
    </>
  );
}
