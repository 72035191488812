import { getArticleConfig } from "library/api/kita";
import Button from "library/common/commonComponents/Buttons/Button";
import { KitaPanelWrapper } from "library/common/commonComponents/KitaPanelWrapper";
import SanitizedTranslation from "library/common/commonComponents/SanitizedTranslation";
import { Wrapper } from "library/common/commonComponents/Wrapper";
import React, { useState, useRef, useEffect } from "react";
import styles from "./articleMainPage.module.scss"
import { useNavigate } from 'react-router-dom';

import { CONTRACT_ONLY_KITAS, AWOMEMBER_LINK } from 'library/common/commonConstants/kitaConstants';

import useReactRouter from 'use-react-router';

export function ArticleMainPage() {

    const [config, setConfig] = useState({});

    useEffect(() => {
        getArticleConfig().then((res) => {
            setConfig(res.data)
        })
    }, [])


    const { location, history } = useReactRouter();

    const goToArticlePage = (link) => {
        history.push(link);
    }


    const goToAboutPage = () => {
        window.location.href = AWOMEMBER_LINK;
    };

    return (
        <KitaPanelWrapper>
            <Wrapper>
                <div className={styles.mainContainer}>
                    <div className={styles.rowContainer}>
                        <Button
                            type="primary"
                            onClick={() => goToArticlePage("/firstArticle")}
                            className={styles.buttonStyle}
                        >
                            <span>Was wir tun und was uns besonders macht</span>
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => goToArticlePage("/secondArticle")}
                            className={styles.buttonStyle}
                        >
                            Gestern – Heute – Morgen
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => goToArticlePage("/thirdArticle")}
                            className={styles.buttonStyle}
                        >
                            Unser Vorstand
                        </Button>
                    </div>
                    <div className={styles.rowContainer}>
                        <Button
                            type="primary"
                            onClick={() => goToArticlePage("/fourthArticle")}
                            className={styles.buttonStyle}
                        >
                            Unser "Zuhause“, unsere Begegnungsstätte
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => goToArticlePage("/fifthArticle")}
                            className={styles.buttonStyle}
                        >
                            Noch Fragen?
                        </Button>
                        <div className={styles.buttonEmpty}></div>
                    </div>
                </div>
                <div className={styles.footerContainer}>
                    <div className={styles.rowFooterContainer}>
                        <div className={styles.buttonWrap}>
                            <Button
                                onClick={() => goToArticlePage("/calendar")}
                                className={styles.footerButton}
                            >
                                <i className="fa fa-calendar" />
                            </Button>
                            <span className={styles.footerSpan}>Was, wann, wo?</span>
                        </div>
                        <div className={styles.buttonWrap}>
                            <Button
                                onClick={() => goToArticlePage("/dashboard")}
                                className={styles.footerButton}
                            >
                                <i className="fa fa-book" />
                            </Button>
                            <span className={styles.footerSpan}>Aktuelles</span>
                        </div>
                        <div className={styles.buttonWrap}>
                            <Button
                                onClick={goToAboutPage}
                                className={styles.footerButton}
                            >
                                <i className="fa fa-heart" />
                            </Button>
                            <span className={styles.footerSpan}>Mitglied werden</span>
                        </div>
                    </div>
                </div>


            </Wrapper>
        </KitaPanelWrapper>
    )
}