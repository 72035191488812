import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { getAllKitasWithAllData } from 'library/api/kita';
import Tabs from 'library/common/commonComponents/Tabs';
import useSiteTitle from 'library/common/commonHooks/useSiteTitle';
import HolidayTimesFrame from 'modules/AccountSettings/accountSettingsFrames/HolidaysFrame';
import SicknessesFrame from 'modules/AccountSettings/accountSettingsFrames/SicknessesFrame';
import LogsFrame from 'modules/AccountSettings/accountSettingsFrames/LogsFrame';
import KitaFrameList from './kitaFrameFrames/KitaFrameList/KitaFrameListContainer';
import ChildExitDateList from "./kitaFrameFrames/ChildExitDateList";
import KitaFrameApproval from './kitaFrameFrames/KitaFrameApproval';
import KitaUserFieldsList from './kitaFrameFrames/KitaFieldsList/KitaUserFieldsList';
import KitaKidFieldsList from './kitaFrameFrames/KitaFieldsList/KitaKidFieldsList';
import KitaOpeningHours from './kitaFrameFrames/KitaOpeningHours';

import styles from './kitaFrame.module.scss';

export default function KitaFrame(activeKita) {
  useSiteTitle('AdministrationKitas');
  const { t } = useTranslation();

  const [currentTab, setCurrentTab] = useState(0);

  const [kitas, setKitas] = useState([]);
  let approveKita = false;

  useEffect(() => {
    getAllKitasWithAllData().then(res => {
      setKitas(res.data.content);
    });
  }, []);

  const providerKitas = [];
  // eslint-disable-next-line array-callback-return
  kitas.map(kita => {
    if (kita.providerId === activeKita.activeKita.kitaId) {
      providerKitas.push(kita);
      if (kita.kitaProviderStatus === 'PENDING') {
      }
      approveKita = true;
    }
  });

  const tabs = [
    {
      title: t('Administration.Kita.Kita'),
      component: <KitaFrameList />,
      path: 'kita',
    },
    {
      component: <KitaUserFieldsList />,
      title: t('Administation.Kita.UserFields'),
      path: 'user-fields',
    },
    {
      title: t('Administation.Kita.KidFields'),
      component: <KitaKidFieldsList />,
      path: 'kid-fields',
    },
    {
      title: t('Administation.Kita.OpeningHours'),
      component: <KitaOpeningHours activeKita={activeKita}/>,
      path: 'kita-opening-hours',
    },
    {
      title: t('Administration.SettingsSection.Holidays'),
      path: 'holidays',
      component: <HolidayTimesFrame />,
    },
    {
      title: t('Administration.SettingsSection.Sicknesses'),
      path: 'sicknesses',
      component: <SicknessesFrame />,
    },
    {
      title: t('Administration.SettingsSection.Child exit dates'),
      path: 'child-exit-dates',
      component: <ChildExitDateList />,
    }
  ];

  if (window.location.hostname.toLowerCase().includes('gfz-app.ch') || window.location.hostname.toLowerCase().includes('staging2.safe2connect.org')) {
    tabs.push({
      title: t('Administration.Logs'),
      path: 'logs',
      component: <LogsFrame />,
    },);
  }

  if (approveKita) {
    tabs.push({
      title: t('Administration.Kita.Approve Kita'),
      component: (
        <KitaFrameApproval providerKitas={providerKitas} activeKita={activeKita.activeKita} />
      ),
      path: 'waiting-for-kita-approval',
    });
  }

  return (
    <div className={styles.container}>
      <Tabs
        tabs={tabs}
        currentTab={tabs[currentTab].path}
        onChange={(_, index) => setCurrentTab(index)}
      />
    </div>
  );
}
