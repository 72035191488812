import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import bgwLogo from 'resources/images/bgw/bgwLogo_png.png';
import gfzLogo from 'resources/images/gfz/gfz_logo_small.png';
import { CONTRACT_ONLY_KITAS } from '../../../../../commonConstants/kitaConstants';

export default function useTranslatedLinks(
  modules,
  googleTranslatorActive,
  kitaId,
  kitaAdmin,
  employee,
  isVirtualKita,
  hasDefaultContract,
  allowParentsFillOut,
  kitaName,
  kitaDescription,
  userEmail,
) {
  const { t } = useTranslation();

  const [locationHost] = useState(window.location.hostname);

  function kitaOrDescriptionNameIncludesBetreuungsverträge(kitaName, kitaDescription) {
    if (
      (kitaName && typeof kitaName !== 'undefined' && kitaName.includes('Betreuungsverträge')) ||
      (kitaDescription &&
        typeof kitaDescription !== 'undefined' &&
        kitaDescription.includes('Betreuungsverträge'))
    ) {
      return true;
    }
    return false;
  }

  let links = [
    {
      to: '/dashboard',
      title: t('Header.Bottombar.Menu.dashboard'),
      faIcon: 'fa-tachometer',
    },
    {
      to: '/account-settings/languages',
      title: t('AccountSettings.Languages'),
      faIcon: 'fa-flag',
    },
    {
      to: '/help',
      title: t('Header.Bottombar.Menu.help'),
      faIcon: 'fa-question-circle',
    },
    {
      to: '/corona',
      title: t('Header.Bottombar.Menu.corona'),
      faIcon: 'fa-question-circle',
    },
  ];

  if (!googleTranslatorActive) {
    links = links.filter(link => link.to !== '/account-settings/languages');
  }

  if (
    !locationHost.includes('stramplerbande') ||
    kitaId === 70 ||
    kitaId === '70' ||
    kitaId === 1131 ||
    kitaId === '1131' ||
    kitaId == null ||
    locationHost.toLowerCase().includes('staging.stramplerbande.org')
  ) {
    links = links.filter(link => link.to !== '/publicgroups');
    links = links.filter(link => link.to !== '/corona');
  }

  // Remove corona page temporary from stramplerbande.org to get accepted from appstore
  if (locationHost.includes('stramplerbande')) {
    links = links.filter(link => link.to !== '/corona');
  }

  const calendarModule = modules.find(module => module.moduleKey === 'calendar');

  if (calendarModule && calendarModule.activeStatus) {
    links.splice(1, 0, {
      to: '/calendar',
      title: t('Header.Bottombar.Menu.calendar'),
      faIcon: 'fa-calendar',
    });
  }
  const suggestionBoxModule = modules.find(module => module.moduleKey === 'suggestion_box');
  if (suggestionBoxModule && suggestionBoxModule.activeStatus) {
    links.push({
      to: '/suggestionbox',
      title: t('Header.Bottombar.Menu.suggestion_box'),
      faIcon: 'fa-comments-o',
    });
  }

  const taxConsultantModule = modules.find(module => module.moduleKey === 'tax_consultant');
  if (taxConsultantModule && taxConsultantModule.activeStatus) {
    links.push({
      to: '/taxconsultant',
      title: t('Header.Bottombar.Menu.taxconsultant'),
      faIcon: 'fa-sticky-note-o',
    });
  }

  if (
    locationHost.toLowerCase().includes('staging2.safe2connect.org') ||
    locationHost.toLowerCase().includes('awo-immer-dabei.safe2connect.org')
  ) {
    links.push({
      to: '/awowwinfo',
      title: t('Header.Awowwinfo'),
      faIcon: 'fa-info-circle',
    });
    links.push({
      to: '/loggedinregistration',
      title: t('Header.Bottombar.Menu.Registration'),
      faIcon: 'fa-user-plus',
    });
  }

  if (
    (locationHost.toLowerCase().includes('staging.stramplerbande.org') ||
      locationHost.toLowerCase().includes('stramplerbande.org') ||
      locationHost.toLowerCase().includes('awobamberg.safe2connect.org') ||
      locationHost.toLowerCase().includes('diakoniehasenbergl-elternapp.safe2connect.org') ||
      locationHost.toLowerCase().includes('staging1.safe2connect.org') ||
      kitaId === 1719) &&
    kitaAdmin
  ) {
    links.push({
      to: '/bgwfaq',
      title: t('Header.Bottombar.Menu.Bgw FAQ'),
      imageIcon: bgwLogo,
    });
  }

  if (
    (kitaAdmin || employee) &&
    !isVirtualKita &&
    (kitaId === 5 ||
      kitaId === 1405 ||
      kitaId === 1715 ||
      kitaId === 1763 ||
      kitaId === 1765 ||
      kitaId === 1767 ||
      locationHost.toLowerCase().includes('gfz-app.ch') ||
      locationHost.toLowerCase().includes('staging.stramplerbande.org') ||
      locationHost.toLowerCase().includes('localhost'))
  ) {
    links.push({
      to: '/educationalwork',
      title: t('Header.Educational work'),
      faIcon: 'fa-file-text-o',
    });
  }

  if (
    locationHost.toLowerCase().includes('gfz-app.ch') ||
    locationHost.toLowerCase().includes('localhost') ||
    locationHost.toLowerCase().includes('staging.stramplerbande.org')
  ) {
    links.push({
      to: '/gfzlink',
      title: t('Header.Gfz'),
      imageIcon: gfzLogo,
    });
  }

  if (
    userEmail === 'natascha.knorpp@gfz-zh.ch' ||
    userEmail === 'sebastian.kopp@link-it-isi.de' ||
    userEmail === 'superadminsb@link-it-isi.de'
  ) {
    links.push({
      to: '/releasenotes',
      title: t('Header.Releasenotes'),
      faIcon: 'fa-info-circle',
    });
  }

  if (
    locationHost.toLowerCase().includes('localhost') ||
    locationHost.toLowerCase().includes('staging.stramplerbande.org') ||
    locationHost.toLowerCase().includes('gfz-app.ch') ||
    locationHost.toLowerCase().includes('dev.stramplerbande.org')
  ) {
    if (!isVirtualKita) {
      links.push({
        to: '/gfzforms',
        title: t('Header.Gfz forms'),
        faIcon: 'fa-file',
      });
    }
  }

  if (
    kitaId === 2101 ||
    kitaId === 2777 ||
    locationHost.toLowerCase().includes('ivs.safe2connect.org') ||
    locationHost.toLowerCase().includes('netzwerk.himmels.app') ||
    locationHost.toLowerCase().includes('schlicker.safe2connect.org') ||
    locationHost.toLowerCase().includes('staging1.safe2connect.org') ||
    locationHost.toLowerCase().includes('dev1.safe2connect.org') ||
    locationHost.toLowerCase().includes('gesher.safe2connect.org') ||
    locationHost.toLowerCase().includes('evalino.safe2connect.org')
  ) {
    links.push({
      to: '/videocall',
      title: t('Header.Bottombar.Menu.videocall'),
      faIcon: 'fa-phone',
    });
  }

  if (
    (hasDefaultContract && (allowParentsFillOut || employee || kitaAdmin)) ||
    kitaOrDescriptionNameIncludesBetreuungsverträge(kitaName, kitaDescription)
  ) {
    links.push({
      to: '/forms/defaultContract/edit',
      title: kitaOrDescriptionNameIncludesBetreuungsverträge(kitaName, kitaDescription)
        ? t('Header.DefaultContractEdit')
        : t('Header.DefaultContractEditForm'),
      faIcon: 'fa-file',
    });

    if (
      CONTRACT_ONLY_KITAS.includes(kitaId) ||
      kitaOrDescriptionNameIncludesBetreuungsverträge(kitaName, kitaDescription)
    ) {
      links = links.filter(link =>
        ['/forms/defaultContract/edit', '/account-settings/languages', '/help'].includes(link.to),
      );
    }
  }

  return links;
}
