import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import CardDropdown from 'library/common/commonComponents/CardDropdown';
import Button from 'library/common/commonComponents/Buttons/Button';
import ListItemWithLogo from 'library/common/commonComponents/ListItems/ListItemWithLogo';
import Loader from 'library/common/commonComponents/Loader';
import HelpText from 'library/common/commonComponents/HelpText';
import useGroupsLoading from 'library/common/commonHooks/groups/useGroupsLoading';
import { getLetterInLogo } from 'library/utilities/groups';
import { sortItems } from 'library/utilities/sort';
import { filterIsNotOnlyPublicKitaMember } from 'library/utilities/kitaChecks';
import { isAwoWW } from 'library/api/tenantConfig';
import { isPublicUser } from 'library/utilities/user';

import styles from './dropdownSpacesSearch.module.scss';

export default function DropdownSpacesSearch({
  isOpened,
  closeDropdown,
  setCreateGroupMode,
  userInfo,
  activeKita,
  kitas,
}) {
  let { groups, isLoading, loadGroups } = useGroupsLoading();

  useEffect(() => {
    if (isOpened) {
      loadGroups();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpened]);

  const { t } = useTranslation();

  const [showFavoriteGroups, setShowFavoriteGroups] = useState(false);
  const [value, setValue] = useState('');
  useEffect(() => {
    loadGroups(value);
    // eslint-disable-next-line
  }, [value]);

  if (userInfo.administrationAccess === true || userInfo.employee === true) {
    if (groups && groups !== 'undefined' && groups.length > 0) {
      groups = groups.filter(item => item.groupName.slice(-11) !== '(Portfolio)');
    }
  }

  function generateInfotafelLink() {
    if (kitas && kitas.kitas && kitas.kitas.length > 1) {
      return `/dashboard?kitaId=${kitas.kitas.find(element => element.virtual === true).kitaId}`;
    } else {
      return '';
    }
  }

  return (
    isOpened && (
      <div className={styles.dropdown}>
        <CardDropdown
          /* header={
            <div className={styles.inputWrapper}>
              <Input
                className={styles.input}
                type='text'
                placeholder={t('Header.Bottombar.SpacesDropdown.Search')}
                faIcon='fa-book'
                value={value}
                onChange={({ target }) => setValue(target.value)}
              />
              {value.length > 0 && (
                <i
                  className={cn('fa fa-times-circle', styles.inputWrapperReset)}
                  onClick={() => setValue('')}
                />
              )}
            </div>
          } */
          footer={
            <div className={styles.buttonsContainer}>
              {!activeKita.virtual && userInfo && userInfo.administrationAccess && (
                <div className={styles.createButtonContainer}>
                  <Button
                    type={isAwoWW() ? 'awoww' : 'info'}
                    className={styles.createButton}
                    onClick={() => setCreateGroupMode(true)}
                    isFullWidth
                  >
                    {t('Header.Bottombar.SpacesDropdown.Create new space')}
                  </Button>
                </div>
              )}
              {isAwoWW() && (
                <Link to='/public-groups'>
                  <Button
                    type={isAwoWW() ? 'awoww' : 'info'}
                    isFullWidth
                    onClick={() => requestAnimationFrame(closeDropdown)}
                  >
                    {t('Header.Alle AWO-Gruppen entdecken')}
                  </Button>
                </Link>
              )}
              {isAwoWW() && !isPublicUser(userInfo) && (
                <Link to='/public-groups?type=member'>
                  <Button
                    type={isAwoWW() ? 'awoww' : 'info'}
                    isFullWidth
                    onClick={() => requestAnimationFrame(closeDropdown)}
                  >
                    {t('Header.AlleGroupMember')}
                  </Button>
                </Link>
              )}
              {kitas?.kitas?.length > 1 && isAwoWW() && (
                <Button
                  type={isAwoWW() ? 'awoww' : 'info'}
                  isFullWidth
                  onClick={() => (window.location.href = generateInfotafelLink())}
                >
                  {t('Header.Meine Infotafel')}
                </Button>
              )}
            </div>
          }
        >
          <div>
            {isLoading ? (
              <div className={styles.searchResultsLoader}>
                <Loader />
              </div>
            ) : groups.length > 0 ? (
              <div>
                {showFavoriteGroups ? (
                  <>
                    <div className={styles.favoriteButtonWrapper}>
                      <Button
                        type='info'
                        onClick={() => setShowFavoriteGroups(false)}
                      >
                        {t('Header.Bottombar.SpacesDropdown.Back to main list')}
                      </Button>
                    </div>
                    <div className={styles.searchResults}>
                      {sortItems(groups.filter(item => !item.globalPublicGroup && item.favorite), 'groupName').map(
                        group => (
                          <ListItemWithLogo
                            key={group.id}
                            to={'/groups/' + group.id}
                            title={<strong>{group.groupName}</strong>}
                            description={group.groupDescription}
                            backgroundColor={group.colourCode}
                            letter={getLetterInLogo(group.groupName)}
                            groupInfo={group}
                            withImageResize
                            onClick={() => requestAnimationFrame(closeDropdown)}
                            isShortDescription
                          />
                        ),
                      )}
                    </div>
                    {groups.filter(item => item.globalPublicGroup && item.favorite).length > 0 &&
                      !window.location.hostname.toLowerCase().includes('netzwerk.himmels.app') &&
                      !window.location.hostname.toLowerCase().includes('zuckertag.safe2connect.org') &&
                      !window.location.hostname.toLowerCase().includes('mucki.safe2connect.org') &&
                      !window.location.hostname
                        .toLowerCase()
                        .includes('hueckelhoven.safe2connect.org') &&
                      !window.location.hostname.toLowerCase().includes('app.safe2connect.org') &&
                      !window.location.hostname.toLowerCase().includes('casadonbosco.safe2connect.org') &&
                      !window.location.hostname.toLowerCase().includes('gemeindemoosthenning.safe2connect.org') &&
                      !window.location.hostname.toLowerCase().includes('indag.safe2connect.org') &&
                      !window.location.hostname.toLowerCase().includes('diekita.safe2connect.org') &&
                      activeKita?.kitaId !== 765 &&
                      !activeKita?.kitaName?.toLowerCase().includes('versuchsdorf') &&
                      !activeKita?.kitaName?.toLowerCase().includes('testhausen') &&
                      !activeKita?.kitaName?.toLowerCase().includes('musterstadt') &&
                      !window.location.hostname.toLowerCase().includes('weltensammler.safe2connect.org') &&
                      !window.location.hostname
                        .toLowerCase()
                        .includes('4schoolkidz.safe2connect.org') &&
                      filterIsNotOnlyPublicKitaMember(kitas.kitas) && (
                        <div className={styles.globalPublicGroupWrapper}>
                          {groups
                            .filter(item => item.globalPublicGroup && item.favorite)
                            .map(group => (
                              <ListItemWithLogo
                                key={group.id}
                                to={'/groups/' + group.id}
                                title={<strong>{group.groupName}</strong>}
                                description={group.groupDescription}
                                backgroundColor={group.colourCode}
                                letter={getLetterInLogo(group.groupName)}
                                groupInfo={group}
                                withImageResize
                                onClick={() => requestAnimationFrame(closeDropdown)}
                                isShortDescription
                              />
                            ))}
                        </div>
                      )}
                  </>
                ) : (
                  <>
                    {!userInfo.superAdminStatus && isAwoWW() && !activeKita.virtual && (
                      <div className={styles.favoriteButtonWrapper}>
                        <Button
                          type='awoww'
                          onClick={() => setShowFavoriteGroups(true)}
                        >
                          {t('Header.Bottombar.SpacesDropdown.My Favorites')}
                        </Button>
                      </div>
                    )}
                    {!activeKita.virtual && (
                      <div className={styles.searchResults}>
                        {sortItems(groups.filter(item => !item.globalPublicGroup), 'groupName').map(
                          group => (
                            <ListItemWithLogo
                              key={group.id}
                              to={'/groups/' + group.id}
                              title={<strong>{group.groupName}</strong>}
                              description={group.groupDescription}
                              backgroundColor={group.colourCode}
                              letter={getLetterInLogo(group.groupName)}
                              groupInfo={group}
                              withImageResize
                              onClick={() => requestAnimationFrame(closeDropdown)}
                              isShortDescription
                            />
                          ),
                        )}
                      </div>
                    )}
                    {groups.filter(item => item.globalPublicGroup).length > 0 &&
                      !window.location.hostname.toLowerCase().includes('netzwerk.himmels.app') &&
                      !window.location.hostname.toLowerCase().includes('zuckertag.safe2connect.org') &&
                      !window.location.hostname.toLowerCase().includes('mucki.safe2connect.org') &&
                      !window.location.hostname
                        .toLowerCase()
                        .includes('hueckelhoven.safe2connect.org') &&
                      !window.location.hostname.toLowerCase().includes('app.safe2connect.org') &&
                      !window.location.hostname.toLowerCase().includes('casadonbosco.safe2connect.org') &&
                      !window.location.hostname.toLowerCase().includes('gemeindemoosthenning.safe2connect.org') &&
                      !window.location.hostname.toLowerCase().includes('indag.safe2connect.org') &&
                      !window.location.hostname.toLowerCase().includes('diekita.safe2connect.org') &&
                      activeKita?.kitaId !== 765 &&
                      !activeKita?.kitaName?.toLowerCase().includes('versuchsdorf') &&
                      !activeKita?.kitaName?.toLowerCase().includes('testhausen') &&
                      !activeKita?.kitaName?.toLowerCase().includes('musterstadt') &&
                      !window.location.hostname.toLowerCase().includes('weltensammler.safe2connect.org') &&
                      !window.location.hostname
                        .toLowerCase()
                        .includes('4schoolkidz.safe2connect.org') &&
                      filterIsNotOnlyPublicKitaMember(kitas.kitas) && (
                        <div className={styles.globalPublicGroupWrapper}>
                          {groups
                            .filter(item => item.globalPublicGroup)
                            .map(group => (
                              <ListItemWithLogo
                                key={group.id}
                                to={'/groups/' + group.id}
                                title={<strong>{group.groupName}</strong>}
                                description={group.groupDescription}
                                backgroundColor={group.colourCode}
                                letter={getLetterInLogo(group.groupName)}
                                groupInfo={group}
                                withImageResize
                                onClick={() => requestAnimationFrame(closeDropdown)}
                                isShortDescription
                              />
                            ))}
                        </div>
                      )}
                  </>
                )}
              </div>
            ) : (
              <div className={styles.messageWarning}>
                <HelpText>{t('Header.Bottombar.SpacesDropdown.No results')}</HelpText>
              </div>
            )}
          </div>
        </CardDropdown>
      </div>
    )
  );
}
