import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import ReactCrop from 'react-image-crop';
import cn from 'classnames';

import { getAllKitasWithAllData } from 'library/api/kita';

import ColorPickerButton from 'library/common/commonComponents/Buttons/ColorPickerButton';
import UploadButton from 'library/common/commonComponents/Buttons/UploadButton/UploadButtonContainer';
import DeleteButton from 'library/common/commonComponents/Buttons/DeleteButton';
import Select from 'library/common/commonComponents/Inputs/Select';
import Popup from 'library/common/commonComponents/Popups/Popup';
import Button from 'library/common/commonComponents/Buttons/Button';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import Label from 'library/common/commonComponents/Label';
import Input from 'library/common/commonComponents/Inputs/Input';
import TextArea from 'library/common/commonComponents/Inputs/TextArea';
import Checkbox from 'library/common/commonComponents/Checkbox';
import useCreateKita, { getCityOptions } from './hooks/useCreateKita';
import { getKitaUserFields } from 'library/api/userFields';
import { isAwoWW } from 'library/api/tenantConfig';

import styles from './createKita.module.scss';

export default function CreateKita({
  closePopup,
  setNewKita,
  isEditing,
  fields,
  superAdminStatus,
  showBottomNotification,
}) {
  const { t } = useTranslation();
  const activeKita = useSelector(state => state.kitaReducer.activeKita);
  const awoWW = isAwoWW();

  const [isLoadingKitaCode, setIsLoadingKitaCode] = useState(false);
  const [providerCheck, setProviderCheck] = useState(false);
  const [providers, setProviders] = useState([]);
  const [formProvider, setFormProvider] = useState(null);
  const [kitaFields, setKitaFields] = useState(null);

  const [kitaLogoSrc, setKitaLogoSrc] = useState('');
  const [kitaVerificationImageSources, setKitaVerificationImageSources] = useState({
    image1: '',
    image2: '',
    image3: '',
    image4: '',
  });

  const providerCheckHandler = () => {
    if (!providerCheck) {
      setProviderCheck(true);
    } else {
      setProviderCheck(false);
    }
  };

  useEffect(() => {
    getAllKitasWithAllData().then(res => {
      // eslint-disable-next-line no-unused-vars
      const kitaArray = res.data.content.map(kita => {
        let kitaTypeId = 0;
        if (kita.kitaType !== null) {
          kitaTypeId = kita.kitaType.id;
        }
        return {
          value: kita.id,
          label: kita.kitaName,
          id: kitaTypeId,
          zip: kita.zipCode,
          kitaCode: kita.kitaCode,
          pending: '',
          providerId: kita.providerId,
        };
      });
      let providerArray = [{ label: t('Administration.Kita.Choose a provider') }];

      // eslint-disable-next-line array-callback-return
      kitaArray.map(kita => {
        if (kita.providerId === 0) {
          providerArray.push(kita);
        }
      });
      // providerArray = providerArray.filter((item) => item.id === 45);
      setFormProvider(providerArray[0]);
      setProviders(providerArray);
    });
  }, []);

  useEffect(()=>{
    const fetchKitaFields = async() => {
      if(activeKita.kitaId && awoWW) {
        try {
          const kitaFields = (await getKitaUserFields(activeKita.kitaId)).data;
          setKitaFields(kitaFields.fields);
        } catch (error) {
          console.log(error);
        }
      }
    }
    fetchKitaFields();
  },[activeKita])

  const {
    name,
    setName,
    nameError,
    setNameError,
    email,
    setEmail,
    emailError,
    setEmailError,
    kitaChiefName,
    setKitaChiefName,
    phoneNo,
    setPhoneNo,
    phoneNoError,
    setPhoneNoError,
    street,
    setStreet,
    streetError,
    setStreetError,
    city,
    setCity,
    cityError,
    setCityError,
    zipCode,
    setZipCode,
    zipCodeError,
    setZipCodeError,
    houseNo,
    setHouseNo,
    houseNoError,
    setHouseNoError,
    belongedCity,
    setBelongedCity,
    kitaType,
    setKitaType,
    kitaTypes,
    description,
    setDescription,
    kitaCode,
    kitaCodeError,
    createKita,
    createKitaCode,
    colorCode,
    setColorCode,
    isLoading,
    crop,
    setCrop,
    logoUrl,
    uploadFile,
    deleteFile,
    verificationImages,
    defaultImages,
    verificationImageCrops,
    verificationImageUrls,
    uploadVerificationImageFile,
    updateVerificationImageCrop,
    deleteVerificationImageFile,
    imagesFetched,
    providerZip,
    setProviderZip,
    providerName,
    setProviderName,
    providerZipError,
    setProviderZipError,
    providerCode,
    setProviderCode,
    providerCodeError,
    setProviderCodeError,
    dataProtectionOfficerName,
    setDataProtectionOfficerName,
    dataProtectionOfficerNameError,
    setDataProtectionOfficerNameError,
    dataProtectionOfficerCompany,
    setDataProtectionOfficerCompany,
    dataProtectionOfficerCompanyError,
    setDataProtectionOfficerCompanyError,
    dataProtectionOfficerCity,
    setDataProtectionOfficerCity,
    dataProtectionOfficerCityError,
    setDataProtectionOfficerCityError,
    dataProtectionOfficerStreet,
    setDataProtectionOfficerStreet,
    dataProtectionOfficerStreetError,
    setDataProtectionOfficerStreetError,
    dataProtectionOfficerZip,
    setDataProtectionOfficerZip,
    dataProtectionOfficerZipError,
    setDataProtectionOfficerZipError,
    dataProtectionOfficerPhone,
    setDataProtectionOfficerPhone,
    dataProtectionOfficerPhoneError,
    setDataProtectionOfficerPhoneError,
    dataProtectionOfficerEmail,
    setDataProtectionOfficerEmail,
    dataProtectionOfficerEmailError,
    setDataProtectionOfficerEmailError,
    dataProcessingName,
    setDataProcessingName,
    dataProcessingNameError,
    setDataProcessingNameError,
    dataProcessingCompany,
    setDataProcessingCompany,
    dataProcessingCompanyError,
    setDataProcessingCompanyError,
    dataProcessingCity,
    setDataProcessingCity,
    dataProcessingCityError,
    setDataProcessingCityError,
    dataProcessingStreet,
    setDataProcessingStreet,
    dataProcessingStreetError,
    setDataProcessingStreetError,
    dataProcessingZip,
    setDataProcessingZip,
    dataProcessingZipError,
    setDataProcessingZipError,
    dataProcessingPhone,
    setDataProcessingPhone,
    dataProcessingPhoneError,
    setDataProcessingPhoneError,
    dataProcessingEmail,
    setDataProcessingEmail,
    dataProcessingEmailError,
    setDataProcessingEmailError,
    religion,
    setReligion,
    organizationName,
    setOrganizationNameError,
    setOrganizationName,
    organizationNameError,
    organizationRepresentedBy,
    setOrganizationRepresentedByError,
    setOrganizationRepresentedBy,
    organizationRepresentedByError,
    organizationAddress,
    setOrganizationAddressError,
    setOrganizationAddress,
    organizationAddressError,
    holidayCares,
    setHolidayCares,
    additionalHolidayCareLabel,
    setAdditionalHolidayCareLabel,
    additionalHolidayCareStartDate,
    setAdditionalHolidayCareStartDate,
    additionalHolidayCareEndDate,
    setAdditionalHolidayCareEndDate,
    additionalHolidayCareStartTime,
    setAdditionalHolidayCareStartTime,
    additionalHolidayCareEndTime,
    setAdditionalHolidayCareEndTime,
    isACity,
    setIsACity,
  } = useCreateKita({
    closePopup,
    setNewKita,
    isEditing,
    fields: fields || {},
    showBottomNotification,
    formProvider,
    providerCheck,
  });

  useEffect(() => {
    setKitaLogoSrc(`${logoUrl}$&t=${Date.now()}&original=true`);
  }, []);

  useEffect(() => {
    const imageSources = {};
    for (let i = 1; i <= 4; i++) {
      const imageKey = `image${i}`;
      const isDefault = verificationImages[imageKey]?.isDefault;
      const imageUrl = verificationImageUrls[imageKey];

      imageSources[imageKey] = isDefault ? imageUrl : `${imageUrl}&t=${Date.now()}&original=true`;
    }
    setKitaVerificationImageSources(imageSources);
  }, [imagesFetched]);

  // eslint-disable-next-line array-callback-return
  kitaTypes.map(types => {
    if (types.kitaId === kitaType) {
      setKitaType(types);
    }
  });

  const handleCreateKitaCode = async () => {
    if (isLoadingKitaCode) {
      return;
    }
    setIsLoadingKitaCode(true);
    await createKitaCode();
    setIsLoadingKitaCode(false);
  };

  const uploadFileHandler = file => {
    setKitaLogoSrc(URL.createObjectURL(file[0]));
    uploadFile(file);
  };

  const uploadVerificationImageFileHandler = (file, imageKey) => {
    setKitaVerificationImageSources({
      ...kitaVerificationImageSources,
      [imageKey]: URL.createObjectURL(file[0]),
    });
    uploadVerificationImageFile(file, imageKey);
  };

  const deleteVerificationImageHandler = key => {
    setKitaVerificationImageSources({
      ...kitaVerificationImageSources,
      [key]: defaultImages[key].url,
    });
    deleteVerificationImageFile(key);
  };

  // eslint-disable-next-line max-len
  let isProvider = false;
  const canShowKitaCodeButton = !isEditing || (isEditing && superAdminStatus);
  let canShowProviderButton = false;
  if (fields && fields.providerId && fields.providerId !== 'undefined') {
    isProvider = fields.providerId === 0;
    canShowProviderButton =
      !fields.providerId || fields.providerId === 0 || fields.providerId === null;
  } else {
    canShowProviderButton = true;
  }

  const isAddNewHolidayCareDisabled = useMemo(
    () =>
      !additionalHolidayCareLabel ||
      !additionalHolidayCareStartDate ||
      !additionalHolidayCareEndDate ||
      !additionalHolidayCareStartTime ||
      !additionalHolidayCareEndTime ||
      additionalHolidayCareStartDate > additionalHolidayCareEndDate ||
      additionalHolidayCareStartTime > additionalHolidayCareEndDate,
    [
      additionalHolidayCareLabel,
      additionalHolidayCareStartDate,
      additionalHolidayCareEndDate,
      additionalHolidayCareStartTime,
      additionalHolidayCareEndTime,
    ],
  );

  const handleAddNewHolidayCare = () => {
    if (isAddNewHolidayCareDisabled) {
      return;
    }
    if (!holidayCares.find(holidayCare => holidayCare.name === additionalHolidayCareLabel)) {
      setHolidayCares([
        ...holidayCares,
        {
          name: additionalHolidayCareLabel,
          startDate: additionalHolidayCareStartDate,
          endDate: additionalHolidayCareEndDate,
          startTime: additionalHolidayCareStartTime,
          endTime: additionalHolidayCareEndTime,
          isAdditional: true,
        },
      ]);
      setAdditionalHolidayCareLabel('');
      setAdditionalHolidayCareStartDate('');
      setAdditionalHolidayCareEndDate('');
      setAdditionalHolidayCareStartTime('');
      setAdditionalHolidayCareEndTime('');
    }
  };

  const handleRemoveHolidayCare = index => {
    const updatedHolidayCaresData = holidayCares.slice();
    updatedHolidayCaresData.splice(index, 1);
    setHolidayCares(updatedHolidayCaresData);
  };

  const handleHolidayCareChange = (name, field) => e => {
    const updatedHolidayCaresData = holidayCares.map(item => {
      if (item.name === name) {
        return {
          ...item,
          [field]: e.target.value,
        };
      } else {
        return item;
      }
    });

    setHolidayCares(updatedHolidayCaresData);
  };

  const isShowField = (fieldName) => {
    return !awoWW || !isEditing || (kitaFields || {})[fieldName]?.isShownOnUserProfile;
  }

  return (
    <Popup
      isOpened
      closePopup={closePopup}
      size='medium'
      header={
        isEditing ? (
          <Trans i18nKey='KITAsPanel.Edit KITA' />
        ) : (
          <Trans i18nKey='KITAsPanel.Add new KITA' />
        )
      }
      body={
        <>
          {
            isShowField('kitaName') && (
              <div className={styles.inputGroup}>
                <Label type='input'>{t('Administration.Kita.Name')} *</Label>
                <Input
                  value={name}
                  onChange={({ target }) => {
                    setNameError('');
                    setName(target.value);
                  }}
                  placeholder={t('Administration.Kita.Kita Name')}
                  error={nameError}
                  maxLength={255}
                />
                {nameError && <div className={styles.error}>{nameError}</div>}
              </div>
            )
          }
          {
            isShowField('kitaEmail') && (
              <div className={styles.inputGroup}>
                <Label type='input'>{t('Administration.Kita.Email')} *</Label>
                <Input
                  value={email}
                  onChange={({ target }) => {
                    setEmailError('');
                    setEmail(target.value);
                  }}
                  placeholder={t('Administration.Kita.Email')}
                  error={emailError}
                />
                {emailError && <div className={styles.error}>{emailError}</div>}
              </div>
            )
          }
          {
            isShowField('kitaChiefName') && (
              <div className={styles.inputGroup}>
                <Label type='input'>{t('Administration.Kita.Kita chief name')}</Label>
                <Input
                  value={kitaChiefName}
                  onChange={({ target }) => {
                    setKitaChiefName(target.value);
                  }}
                  placeholder={t('Administration.Kita.Kita chief name')}
                  maxLength={255}
                />
              </div>
            )
          }
          {
            isShowField('kitaPhoneNumber') && (
              <div className={styles.inputGroup}>
                <Label type='input'>{t('Administration.Kita.Phone number')} *</Label>
                <Input
                  value={phoneNo}
                  onChange={({ target }) => {
                    setPhoneNoError('');
                    setPhoneNo(target.value);
                  }}
                  placeholder={t('Administration.Kita.Phone number')}
                  error={phoneNoError}
                  maxLength={255}
                />
                {phoneNoError && <div className={styles.error}>{phoneNoError}</div>}
              </div>
          )}
          {
            isShowField('kitaPhoneNumber') && (
              <div className={styles.inputGroup}>
                <Label type='input'>{t('Administration.Kita.Phone number')} *</Label>
                <Input
                  value={phoneNo}
                  onChange={({ target }) => {
                    setPhoneNoError('');
                    setPhoneNo(target.value);
                  }}
                  placeholder={t('Administration.Kita.Phone number')}
                  error={phoneNoError}
                  maxLength={255}
                />
                {phoneNoError && <div className={styles.error}>{phoneNoError}</div>}
              </div>
          )}
          {
            isShowField('kitaAddress') && (
              <div className={styles.inputGroup}>
                <Label type='input'>{t('Administration.Kita.Address')} *</Label>
                <div className={styles.inputHalfContainer}>
                  <div className={styles.inputHalf}>
                    <Input
                      value={city}
                      onChange={({ target }) => {
                        setCityError('');
                        setCity(target.value);
                      }}
                      error={cityError}
                      placeholder={t('Administration.Kita.City Town') + ' *'}
                      maxLength={255}
                    />
                    {cityError && <div className={styles.error}>{cityError}</div>}
                  </div>
                  <div className={styles.inputHalf}>
                    <Input
                      value={street}
                      onChange={({ target }) => {
                        setStreetError('');
                        setStreet(target.value);
                      }}
                      error={streetError}
                      placeholder={t('Administration.Kita.Street address') + ' *'}
                      maxLength={255}
                    />
                    {streetError && <div className={styles.error}>{streetError}</div>}
                  </div>
                  <div className={styles.inputHalf}>
                    <Input
                      value={houseNo}
                      onChange={({ target }) => {
                        setHouseNoError('');
                        setHouseNo(target.value);
                      }}
                      placeholder={t('Administration.Kita.House number') + ' *'}
                      error={houseNoError}
                      maxLength={50}
                    />
                    {houseNoError && <div className={styles.error}>{houseNoError}</div>}
                  </div>
                  <div className={styles.inputHalf}>
                    <Input
                      value={zipCode}
                      onChange={({ target }) => {
                        setZipCodeError('');
                        setZipCode(target.value);
                      }}
                      error={zipCodeError}
                      placeholder={t('Administration.Kita.Zip Code') + ' *'}
                      maxLength={255}
                    />
                    {zipCodeError && <div className={styles.error}>{zipCodeError}</div>}
                  </div>
                </div>
              </div>
          )}
          {
            isAwoWW() && isShowField('kitaIsACity') && (
              <div className={styles.inputGroup}>
                <Label type='input'>{t('Administration.Kita.IsACity')}</Label>
                <Checkbox
                  className={styles.checkbox}
                  name={t('Administration.Kita.Administration.Kita.IsACity')}
                  onChange={()=> setIsACity(!isACity)}
                  isChecked={isACity}
                />
              </div>
            )
          }
          {
            isShowField('kitaOrganization') && (
              <div className={styles.inputGroup}>
                <Label type='input'>{t('Administration.Kita.Organization')} *</Label>
                <div className={styles.inputHalfContainer}>
                  <div className={styles.inputHalf}>
                    <Input
                      value={organizationName}
                      onChange={({ target }) => {
                        setOrganizationNameError('');
                        setOrganizationName(target.value);
                      }}
                      error={organizationNameError}
                      placeholder={t('Administration.Kita.OrganizationName') + ' *'}
                      maxLength={255}
                    />
                    {organizationNameError && (
                      <div className={styles.error}>{organizationNameError}</div>
                    )}
                  </div>
                  <div className={styles.inputHalf}>
                    <Input
                      value={organizationRepresentedBy}
                      onChange={({ target }) => {
                        setOrganizationRepresentedByError('');
                        setOrganizationRepresentedBy(target.value);
                      }}
                      error={organizationRepresentedByError}
                      placeholder={t('Administration.Kita.OrganizationRepresentedBy') + ' *'}
                      maxLength={255}
                    />
                    {organizationRepresentedByError && (
                      <div className={styles.error}>{organizationRepresentedByError}</div>
                    )}
                  </div>
                  <div className={styles.inputHalf}>
                    <Input
                      value={organizationAddress}
                      onChange={({ target }) => {
                        setOrganizationAddressError('');
                        setOrganizationAddress(target.value);
                      }}
                      error={organizationAddressError}
                      placeholder={t('Administration.Kita.OrganizationAddress') + ' *'}
                      maxLength={255}
                    />
                    {organizationAddressError && (
                      <div className={styles.error}>{organizationAddressError}</div>
                    )}
                  </div>
                </div>
              </div>
            )
          }
          {
            isShowField('kitaWebsite') && (
              <div className={styles.inputGroup}>
                <Label type='input'>
                  {t(
                    'Administration.Kita.Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website',
                  )}{' '}
                  *
                </Label>
                <div className={styles.inputHalfContainer}>
                  <div className={styles.inputFull}>
                    <Input
                      value={dataProcessingName}
                      onChange={({ target }) => {
                        setDataProcessingNameError('');
                        setDataProcessingName(target.value);
                      }}
                      placeholder={t('Administration.Kita.DataProcessor Name') + ' *'}
                      error={dataProcessingNameError}
                      maxLength={255}
                    />
                    {dataProcessingNameError && (
                      <div className={styles.error}>{dataProcessingNameError}</div>
                    )}
                  </div>
                  <div className={styles.inputHalf}>
                    <Input
                      value={dataProcessingCompany}
                      onChange={({ target }) => {
                        setDataProcessingCompanyError('');
                        setDataProcessingCompany(target.value);
                      }}
                      placeholder={t('Administration.Kita.DataProcessor Company') + ' *'}
                      error={dataProcessingCompanyError}
                      maxLength={255}
                    />
                    {dataProcessingCompanyError && (
                      <div className={styles.error}>{dataProcessingCompanyError}</div>
                    )}
                  </div>
                  <div className={styles.inputHalf}>
                    <Input
                      value={dataProcessingCity}
                      onChange={({ target }) => {
                        setDataProcessingCityError('');
                        setDataProcessingCity(target.value);
                      }}
                      error={dataProcessingCityError}
                      placeholder={t('Administration.Kita.DataProcessor City Town') + ' *'}
                      maxLength={255}
                    />
                    {dataProcessingCityError && (
                      <div className={styles.error}>{dataProcessingCityError}</div>
                    )}
                  </div>
                  <div className={styles.inputHalf}>
                    <Input
                      value={dataProcessingStreet}
                      onChange={({ target }) => {
                        setDataProcessingStreetError('');
                        setDataProcessingStreet(target.value);
                      }}
                      error={dataProcessingStreetError}
                      placeholder={t('Administration.Kita.DataProcessor Street address') + ' *'}
                      maxLength={255}
                    />
                    {dataProcessingStreetError && (
                      <div className={styles.error}>{dataProcessingStreetError}</div>
                    )}
                  </div>
                  <div className={styles.inputHalf}>
                    <Input
                      value={dataProcessingZip}
                      onChange={({ target }) => {
                        setDataProcessingZipError('');
                        setDataProcessingZip(target.value);
                      }}
                      error={dataProcessingZipError}
                      placeholder={t('Administration.Kita.DataProcessor Zip Code') + ' *'}
                      maxLength={255}
                    />
                    {dataProcessingZipError && (
                      <div className={styles.error}>{dataProcessingZipError}</div>
                    )}
                  </div>
                  <div className={styles.inputHalf}>
                    <Input
                      value={dataProcessingPhone}
                      onChange={({ target }) => {
                        setDataProcessingPhoneError('');
                        setDataProcessingPhone(target.value);
                      }}
                      placeholder={t('Administration.Kita.DataProcessor Phone number')}
                      error={dataProcessingPhoneError}
                      maxLength={255}
                    />
                    {dataProcessingPhoneError && (
                      <div className={styles.error}>{dataProcessingPhoneError}</div>
                    )}
                  </div>
                  <div className={styles.inputHalf}>
                    <Input
                      value={dataProcessingEmail}
                      onChange={({ target }) => {
                        setDataProcessingEmailError('');
                        setDataProcessingEmail(target.value);
                      }}
                      placeholder={t('Administration.Kita.DataProcessor Email')}
                      error={dataProcessingEmailError}
                    />
                    {dataProcessingEmailError && (
                      <div className={styles.error}>{dataProcessingEmailError}</div>
                    )}
                  </div>
                </div>
              </div>
            )
          }
          {
            isShowField('kitaDataProtector') && (
              <div className={styles.inputGroup}>
                <Label type='input'>{t('Administration.Kita.Dataprotector')} *</Label>
                <div className={styles.inputHalfContainer}>
                  <div className={styles.inputFull}>
                    <Input
                      value={dataProtectionOfficerName}
                      onChange={({ target }) => {
                        setDataProtectionOfficerNameError('');
                        setDataProtectionOfficerName(target.value);
                      }}
                      placeholder={t('Administration.Kita.Dataprotector Name') + ' *'}
                      error={dataProtectionOfficerNameError}
                      maxLength={255}
                    />
                    {dataProtectionOfficerNameError && (
                      <div className={styles.error}>{dataProtectionOfficerNameError}</div>
                    )}
                  </div>
                  <div className={styles.inputHalf}>
                    <Input
                      value={dataProtectionOfficerCompany}
                      onChange={({ target }) => {
                        setDataProtectionOfficerCompanyError('');
                        setDataProtectionOfficerCompany(target.value);
                      }}
                      placeholder={t('Administration.Kita.Dataprotector Company') + ' *'}
                      error={dataProtectionOfficerCompanyError}
                      maxLength={255}
                    />
                    {dataProtectionOfficerCompanyError && (
                      <div className={styles.error}>{dataProtectionOfficerCompanyError}</div>
                    )}
                  </div>
                  <div className={styles.inputHalf}>
                    <Input
                      value={dataProtectionOfficerCity}
                      onChange={({ target }) => {
                        setDataProtectionOfficerCityError('');
                        setDataProtectionOfficerCity(target.value);
                      }}
                      error={dataProtectionOfficerCityError}
                      placeholder={t('Administration.Kita.Dataprotector City Town') + ' *'}
                      maxLength={255}
                    />
                    {dataProtectionOfficerCityError && (
                      <div className={styles.error}>{dataProtectionOfficerCityError}</div>
                    )}
                  </div>
                  <div className={styles.inputHalf}>
                    <Input
                      value={dataProtectionOfficerStreet}
                      onChange={({ target }) => {
                        setDataProtectionOfficerStreetError('');
                        setDataProtectionOfficerStreet(target.value);
                      }}
                      error={dataProtectionOfficerStreetError}
                      placeholder={t('Administration.Kita.Dataprotector Street address') + ' *'}
                      maxLength={255}
                    />
                    {dataProtectionOfficerStreetError && (
                      <div className={styles.error}>{dataProtectionOfficerStreetError}</div>
                    )}
                  </div>
                  <div className={styles.inputHalf}>
                    <Input
                      value={dataProtectionOfficerZip}
                      onChange={({ target }) => {
                        setDataProtectionOfficerZipError('');
                        setDataProtectionOfficerZip(target.value);
                      }}
                      error={dataProtectionOfficerZipError}
                      placeholder={t('Administration.Kita.Dataprotector Zip Code') + ' *'}
                      maxLength={255}
                    />
                    {dataProtectionOfficerZipError && (
                      <div className={styles.error}>{dataProtectionOfficerZipError}</div>
                    )}
                  </div>
                  <div className={styles.inputHalf}>
                    <Input
                      value={dataProtectionOfficerPhone}
                      onChange={({ target }) => {
                        setDataProtectionOfficerPhoneError('');
                        setDataProtectionOfficerPhone(target.value);
                      }}
                      placeholder={t('Administration.Kita.Dataprotector Phone number')}
                      error={dataProtectionOfficerPhoneError}
                      maxLength={255}
                    />
                    {dataProtectionOfficerPhoneError && (
                      <div className={styles.error}>{dataProtectionOfficerPhoneError}</div>
                    )}
                  </div>
                  <div className={styles.inputHalf}>
                    <Input
                      value={dataProtectionOfficerEmail}
                      onChange={({ target }) => {
                        setDataProtectionOfficerEmailError('');
                        setDataProtectionOfficerEmail(target.value);
                      }}
                      placeholder={t('Administration.Kita.Dataprotector Email')}
                      error={dataProtectionOfficerEmailError}
                    />
                    {dataProtectionOfficerEmailError && (
                      <div className={styles.error}>{dataProtectionOfficerEmailError}</div>
                    )}
                  </div>
                </div>
              </div>
            )
          }
          {superAdminStatus && (
            <div className={styles.inputGroup}>
              <Label type='input'>{t('Administration.Kita.Kita type')} *</Label>
              <Select
                options={kitaTypes}
                onSelect={data => setKitaType(data)}
                selected={kitaType}
              />
              {canShowProviderButton && !isProvider && (
                <Checkbox
                  className={styles.checkbox}
                  name={t('Administration.Kita.Belongs to provider')}
                  onChange={providerCheckHandler}
                  isChecked={providerCheck}
                />
              )}
            </div>
          )}

          {providerCheck && (
            <React.Fragment>
              <div className={styles.inputHalfContainer}>
                <div className={styles.inputFull}>
                  <Label type='input'>
                    {t('Administration.Kita.Choose the provider with postcode and kitacode')} *
                  </Label>
                  <Select
                    options={providers}
                    onSelect={data => setFormProvider(data)}
                    selected={formProvider}
                  />
                </div>
                <div className={styles.inputHalf}>
                  <Input
                    value={providerZip}
                    onChange={({ target }) => {
                      setProviderZipError('');
                      setProviderZip(target.value);
                    }}
                    placeholder={t('Administration.Kita.Provider Postcode')}
                    error={providerZipError}
                    maxLength={255}
                  />
                  {providerZipError && <div className={styles.error}>{providerZipError}</div>}
                </div>
                <div className={styles.inputHalf}>
                  <Input
                    value={providerCode}
                    onChange={({ target }) => {
                      setProviderCodeError('');
                      setProviderCode(target.value);
                    }}
                    placeholder={t('Administration.Kita.Provider KitaCode')}
                    error={providerCodeError}
                    maxLength={255}
                  />
                  {providerCodeError && <div className={styles.error}>{providerCodeError}</div>}
                </div>
              </div>
            </React.Fragment>
          )}

          {!isProvider && (
            <div className={styles.inputGroup}>
              <Label type='input'>{t('Administration.Kita.Provider')}</Label>
              <Input
                value={providerName}
                onChange={({ target }) => {
                  setProviderName(target.value);
                }}
                placeholder={t('Administration.Kita.Kita Provider')}
              />
            </div>
          )}
          {
            isShowField('kitaReligion') && (
              <div className={styles.inputGroup}>
                <Label type='input'>{t('Administration.Kita.Religion')}</Label>
                <Select
                  options={[
                    { label: t('Administration.Kita.Secular'), id: 1 },
                    { label: t('Administration.Kita.Catholic'), id: 2 },
                    { label: t('Administration.Kita.Protestant'), id: 3 },
                  ]}
                  onSelect={e => setReligion(e)}
                  selected={religion}
                  style={{ zIndex: '10000000000' }}
                />
              </div>
            )
          }
          {
            isShowField('kitaDescription') && (
              <div className={styles.inputGroup}>
                <Label type='input'>{t('Administration.Kita.Description')}</Label>
                <TextArea
                  value={description}
                  onChange={({ target }) => setDescription(target.value)}
                  placeholder={t('Administration.Kita.Kita description')}
                />
              </div>
            )
          }
          {
            isShowField('kitaCreateKitaCode') && (
              <div
                className={cn({
                  [styles.inputGroup]: true,
                  [styles.inputGroupKitaCode]: canShowKitaCodeButton,
                })}
              >
                {canShowKitaCodeButton ? (
                  <ButtonWithLoader
                    type='success'
                    size='sm'
                    onClick={handleCreateKitaCode}
                    className={styles.inputGroupKitaCodeButton}
                    isLoading={isLoadingKitaCode}
                  >
                    <i className='fa fa-plus' /> {t('Administration.Kita.Create Kita code')}
                  </ButtonWithLoader>
                ) : (
                  <Label type='input'>{t('Administration.Kita.Registration code')}</Label>
                )}

                <div className={styles.inputGroupKitaCodeInput}>
                  <Input
                    disabled
                    value={kitaCode}
                    placeholder={t('Administration.Kita.Kita Code') + ' *'}
                    error={kitaCodeError}
                  />
                  {kitaCodeError && <div className={styles.error}>{kitaCodeError}</div>}
                </div>
              </div>
            )
          }
          {
            isShowField('kitaColor') && (
              <div className={styles.inputGroup}>
                <Label type='input'>{t('Administration.Kita.Kita Color')} *</Label>
                <ColorPickerButton color={colorCode} onClick={obj => setColorCode(obj.hex)} />
              </div>
            )
          }
          {
            isShowField('kitaImage') && (
              <div className={styles.inputGroup}>
                <Label type='input'>{t('Administration.Kita.Kita Image')}</Label>
                <div className={styles.uploadButtons}>
                  <UploadButton
                    onChange={file => uploadFileHandler(file)}
                    allowedExtensions={['image/*']}
                  />{' '}
                  {logoUrl && <DeleteButton onClick={deleteFile} className={styles.deleteButton} />}
                </div>
                {logoUrl && (
                  <ReactCrop
                    src={kitaLogoSrc}
                    crop={crop}
                    onChange={(_, newCrop) => setCrop(newCrop)}
                    imageStyle={{ imageRendering: '-webkit-optimize-contrast' }}
                  />
                )}
              </div>
            )
          }
          {
            isShowField('kitaVerifyImages') && (
              <>
              <Label type='input'>{t('Administration.Kita.Verify Images')}</Label>
              <div className={styles.verificationImages}>
                <div className={styles.inputGroup}>
                  <div className={styles.uploadButtons}>
                    <UploadButton
                      onChange={file => uploadVerificationImageFileHandler(file, 'image1')}
                      allowedExtensions={['image/*']}
                    />{' '}
                    {!verificationImages.image1?.isDefault && verificationImageUrls.image1 && (
                      <DeleteButton
                        onClick={() => deleteVerificationImageHandler('image1')}
                        className={styles.deleteButton}
                      />
                    )}
                  </div>
                  {verificationImageUrls.image1 && (
                    <ReactCrop
                      src={kitaVerificationImageSources.image1}
                      crop={verificationImageCrops.image1}
                      onChange={(_, newCrop) => updateVerificationImageCrop('image1', newCrop)}
                      imageStyle={{ imageRendering: '-webkit-optimize-contrast' }}
                    />
                  )}
                </div>

                <div className={styles.inputGroup}>
                  <div className={styles.uploadButtons}>
                    <UploadButton
                      onChange={file => uploadVerificationImageFileHandler(file, 'image2')}
                      allowedExtensions={['image/*']}
                    />{' '}
                    {!verificationImages.image2?.isDefault && verificationImageUrls.image2 && (
                      <DeleteButton
                        onClick={() => deleteVerificationImageHandler('image2')}
                        className={styles.deleteButton}
                      />
                    )}
                  </div>
                  {verificationImageUrls.image2 && (
                    <ReactCrop
                      src={kitaVerificationImageSources.image2}
                      crop={verificationImageCrops.image2}
                      onChange={(_, newCrop) => updateVerificationImageCrop('image2', newCrop)}
                      imageStyle={{ imageRendering: '-webkit-optimize-contrast' }}
                    />
                  )}
                </div>

                <div className={styles.inputGroup}>
                  <div className={styles.uploadButtons}>
                    <UploadButton
                      onChange={file => uploadVerificationImageFileHandler(file, 'image3')}
                      allowedExtensions={['image/*']}
                    />{' '}
                    {!verificationImages.image3?.isDefault && verificationImageUrls.image3 && (
                      <DeleteButton
                        onClick={() => deleteVerificationImageHandler('image3')}
                        className={styles.deleteButton}
                      />
                    )}
                  </div>
                  {verificationImageUrls.image3 && (
                    <ReactCrop
                      src={kitaVerificationImageSources.image3}
                      crop={verificationImageCrops.image3}
                      onChange={(_, newCrop) => updateVerificationImageCrop('image3', newCrop)}
                      imageStyle={{ imageRendering: '-webkit-optimize-contrast' }}
                    />
                  )}
                </div>

                <div className={styles.inputGroup}>
                  <div className={styles.uploadButtons}>
                    <UploadButton
                      onChange={file => uploadVerificationImageFileHandler(file, 'image4')}
                      allowedExtensions={['image/*']}
                    />{' '}
                    {!verificationImages.image4?.isDefault && verificationImageUrls.image4 && (
                      <DeleteButton
                        onClick={() => deleteVerificationImageHandler('image4')}
                        className={styles.deleteButton}
                      />
                    )}
                  </div>
                  {verificationImageUrls.image4 && (
                    <ReactCrop
                      src={kitaVerificationImageSources.image4}
                      crop={verificationImageCrops.image4}
                      onChange={(_, newCrop) => updateVerificationImageCrop('image4', newCrop)}
                      imageStyle={{ imageRendering: '-webkit-optimize-contrast' }}
                    />
                  )}
                </div>
              </div>
              </>
            )
          }
          {
            isShowField('kitaHolidayCare') && (
              <div className={styles.inputGroup}>
                <Label type='input'>{t('Administration.Kita.HolidayCare')}</Label>
                <div className={styles.holidaysCareContainer}>
                  <div className={styles.holidayCare}>
                    <div className={styles.holidayCareHeader}>
                      <div>{t('Administration.Kita.HolidayCare Label')}</div>
                      <div>{t('Administration.Kita.HolidayCare FromDate')}</div>
                      <div>{t('Administration.Kita.HolidayCare ToDate')}</div>
                    </div>
                    {holidayCares.map((holidayCare, index) => (
                      <div className={styles.holidayCareRow} key={index}>
                        <div className={styles.holidayCareFields}>
                          <div className={styles.holidayCareDateRow}>
                            <Input
                              value={holidayCare.name}
                              onChange={handleHolidayCareChange(holidayCare.name, 'name')}
                            />
                            <Input
                              type='date'
                              value={holidayCare.startDate}
                              onChange={handleHolidayCareChange(holidayCare.name, 'startDate')}
                            />
                            <Input
                              type='date'
                              value={holidayCare.endDate}
                              onChange={handleHolidayCareChange(holidayCare.name, 'endDate')}
                            />
                          </div>
                          <div className={styles.holidayCareTimeRow}>
                            <div />
                            <Input
                              type='time'
                              value={holidayCare.startTime}
                              onChange={handleHolidayCareChange(holidayCare.name, 'startDate')}
                            />
                            <Input
                              type='time'
                              value={holidayCare.endTime}
                              onChange={handleHolidayCareChange(holidayCare.name, 'endTime')}
                            />
                          </div>
                        </div>
                        <div>
                          <Button size='sm' onClick={() => handleRemoveHolidayCare(index)}>
                            <i className='fa fa-minus' />
                          </Button>
                        </div>
                      </div>
                    ))}
                    <div className={styles.holidayCareRow}>
                      <div className={styles.holidayCareFields}>
                        <div className={styles.holidayCareDateRow}>
                          <Input
                            placeholder='name'
                            value={additionalHolidayCareLabel}
                            onChange={e => setAdditionalHolidayCareLabel(e.target.value)}
                          />
                          <Input
                            type='date'
                            value={additionalHolidayCareStartDate}
                            onChange={e => setAdditionalHolidayCareStartDate(e.target.value)}
                          />
                          <Input
                            type='date'
                            value={additionalHolidayCareEndDate}
                            onChange={e => setAdditionalHolidayCareEndDate(e.target.value)}
                          />
                        </div>
                        <div className={styles.holidayCareTimeRow}>
                          <div />
                          <Input
                            type='time'
                            value={additionalHolidayCareStartTime}
                            onChange={e => setAdditionalHolidayCareStartTime(e.target.value)}
                          />
                          <Input
                            type='time'
                            value={additionalHolidayCareEndTime}
                            onChange={e => setAdditionalHolidayCareEndTime(e.target.value)}
                          />
                        </div>
                      </div>
                      <div>
                        <Button
                          size='sm'
                          disabled={isAddNewHolidayCareDisabled}
                          onClick={handleAddNewHolidayCare}
                        >
                          <i className='fa fa-plus' />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          }

          <div className={styles.submit}>
            <ButtonWithLoader onClick={createKita} type='primary' isLoading={isLoading}>
              {t(isEditing ? 'Popup.Save' : 'Administration.Kita.Create Kita')}
            </ButtonWithLoader>

            <Button className={styles.submitCancel} onClick={closePopup}>
              {t('Popup.Cancel')}
            </Button>
          </div>
        </>
      }
    />
  );
}
